import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import hasPermission from "../../utilities/access";
import ROUTES, { RouteNode } from "../../routes";
import useAPI from "../../hooks/useAPI";

const MenuGrid = () => {
  const navigate = useNavigate();

  const { profile } = useAPI();

  return (
    <Grid container spacing={2}>
      {ROUTES.filter((route) => {
        return (
          route.showInNav &&
          hasPermission(profile!.access_level, route.requiredAccessLevel)
        );
      }).map((route: RouteNode) => (
        <Grid item xs={route.size} key={route.path}>
          <Button
            style={{
              padding: "20px",
              textAlign: "center",
              background: "white",
              width: "100%",
              height: "100%",
            }}
            onClick={() => navigate(route.path)}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              {route.icon}
              <Typography
                variant="subtitle1"
                style={{ marginTop: "10px", textTransform: "none" }}
                fontWeight={600}
                color={"#444142"}
              >
                {route.label}
              </Typography>
            </Box>
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default MenuGrid;
