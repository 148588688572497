import { FC } from "react";

import { Box, Grid, Typography } from "@mui/material";
import { format } from "date-fns";

import useAPI from "../../hooks/useAPI";

const ProfileForm: FC = () => {
  const { profile } = useAPI();

  if (!profile) {
    return <>Loading...</>;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight={600}>
            Profile Information
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Information name="Phone number" value={profile.phone_number} />
        </Grid>
        <Grid item xs={12}>
          <Information name="Referral code" value={profile.referral_code} />
        </Grid>
        <Grid item xs={12}>
          <Information name="Access level" value={profile.access_level} />
        </Grid>
        <Grid item xs={12}>
          <Information name="Location" value={profile.location} />
        </Grid>
        <Grid item xs={12}>
          <Information name="Language" value={profile.language} />
        </Grid>
        <Grid item xs={12}>
          <Information
            name="Member since"
            value={format(new Date(profile.created_at), "do MMMM yyyy")}
          />
        </Grid>
      </Grid>
    </>
  );
};

const Information: FC<{ name: string; value: string | number }> = ({
  name,
  value,
}) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <Typography variant="body1">{name}:</Typography>
      <Typography variant="body1">{value}</Typography>
    </Box>
  );
};

export default ProfileForm;
