import { FC } from "react";

import { Box, Button, Paper } from "@mui/material";

import NavBar from "../components/navigation/NavBar";
import TermsOfService from "../components/configuration/TermsOfService";
import useAPI from "../hooks/useAPI";

const TermsOfServicePage: FC = () => {
  const { profile } = useAPI();

  return (
    <Box height="100vh" overflow={"clip"} display="flex" flexDirection="column">
      {profile && (
        <Box mt={2}>
          <NavBar />
        </Box>
      )}
      <Box mt={2} />
      <Box height={0.025} />
      <Box flexGrow={1} overflow="auto">
        <Paper>
          <TermsOfService />
          <Box mt={2} />
          <Button fullWidth onClick={() => window.history.back()}>
            Back
          </Button>
        </Paper>
        <Box mt={2} />
      </Box>
    </Box>
  );
};

export default TermsOfServicePage;
