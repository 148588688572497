import { FC, ReactNode, useEffect } from "react";

import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import useAPI from "../hooks/useAPI";

const RestrictedPage: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const navigate = useNavigate();

  const { profile } = useAPI();

  useEffect(() => {
    if (!profile) {
      navigate("/login");
    }
  }, [profile, navigate]);

  if (!profile) {
    return <Typography variant="h6">No profile loaded</Typography>;
  }

  return <>{children}</>;
};

export default RestrictedPage;
