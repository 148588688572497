import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import axios, { AxiosInstance } from "axios";

import {
  AccountsApi,
  AuthenticationApi,
  Configuration,
  ConfigurationApi,
  ConversationsApi,
  MetricsApi,
  PatientsApi,
  Profile,
  TestsApi,
  UtilitiesApi,
} from "../api/koyo";

import APIContext from "../contexts/APIContext";
import CONFIG from "../config";

interface StoredAPIState {
  accessToken: string | null;
  refreshToken: string | null;
  profile: Profile | null;
}

const DEFAULT_STATE: StoredAPIState = {
  accessToken: null,
  refreshToken: null,
  profile: null,
};

const saveState = (state: StoredAPIState): void => {
  try {
    const stateJSON = JSON.stringify(state);
    localStorage.setItem("apiState", stateJSON);
  } catch (error) {
    console.error("Error saving the API state:", error);
  }
};

const loadState = (): StoredAPIState => {
  try {
    const stateJSON = localStorage.getItem("apiState");
    return stateJSON ? JSON.parse(stateJSON) : DEFAULT_STATE;
  } catch (error) {
    console.error("Error loading the API state:", error);
    return DEFAULT_STATE;
  }
};

const APIProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const storedState = loadState();

  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string | null>(
    storedState.accessToken
  );
  const [refreshToken, setRefreshToken] = useState<string | null>(
    storedState.refreshToken
  );
  const [profile, setProfile] = useState<Profile | null>(storedState.profile);

  const refreshAccessToken = useCallback(async () => {
    if (!refreshToken) {
      throw new Error("Refresh token not available");
    }
    try {
      const response = await axios.post(
        "/refresh",
        {
          refresh_token: refreshToken,
        },
        {
          baseURL: `${CONFIG.apiHost}`,
        }
      );

      const {
        access_token,
        refresh_token,
        profile: refreshedProfile,
      } = response.data;
      setAccessToken(access_token);
      setRefreshToken(refresh_token);
      setProfile(refreshedProfile);
      return access_token;
    } catch (error) {
      console.error("Error refreshing access token:", error);
      throw error;
    }
  }, [refreshToken, setAccessToken, setRefreshToken, setProfile]);

  const signOut = useCallback(() => {
    setAccessToken(null);
    setRefreshToken(null);
    setProfile(null);
    saveState(DEFAULT_STATE);
  }, [setAccessToken, setRefreshToken, setProfile]);

  const createApiClient = useCallback((): AxiosInstance => {
    const apiClient = axios.create({
      baseURL: `${CONFIG.apiHost}`,
    });
    apiClient.interceptors.request.use((config) => {
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    });

    apiClient.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        const isTokenRequest = originalRequest.url?.endsWith("/token");
        const isUnauthorizedError = error.response?.status === 401;
        const isRetryAttempt = originalRequest._retry;

        if (!isTokenRequest && isUnauthorizedError && !isRetryAttempt) {
          originalRequest._retry = true; // Marking the request to indicate it has been retried.
          try {
            const newAccessToken = await refreshAccessToken();
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            return axios(originalRequest);
          } catch (refreshError) {
            alert(
              "Your access token has expired. Please sign in again to continue."
            );
            signOut();
            window.location.href = "/";

            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );

    return apiClient;
  }, [accessToken, refreshAccessToken, signOut]);

  const accountsAPI = useMemo(
    () => new AccountsApi(new Configuration(), undefined, createApiClient()),
    [createApiClient]
  );
  const authenticationAPI = useMemo(
    () =>
      new AuthenticationApi(new Configuration(), undefined, createApiClient()),
    [createApiClient]
  );
  const configurationAPI = useMemo(
    () =>
      new ConfigurationApi(new Configuration(), undefined, createApiClient()),
    [createApiClient]
  );
  const conversationsAPI = useMemo(
    () =>
      new ConversationsApi(new Configuration(), undefined, createApiClient()),
    [createApiClient]
  );
  const metricsAPI = useMemo(
    () => new MetricsApi(new Configuration(), undefined, createApiClient()),
    [createApiClient]
  );
  const patientsAPI = useMemo(
    () => new PatientsApi(new Configuration(), undefined, createApiClient()),
    [createApiClient]
  );
  const testsAPI = useMemo(
    () => new TestsApi(new Configuration(), undefined, createApiClient()),
    [createApiClient]
  );
  const utilitiesAPI = useMemo(
    () => new UtilitiesApi(new Configuration(), undefined, createApiClient()),
    [createApiClient]
  );

  const signIn = useCallback(
    async (phone_number: string, password: string) => {
      try {
        const response = await authenticationAPI.generateAccessToken(
          phone_number,
          password
        );
        setAccessToken(response.data.access_token);
        setRefreshToken(response.data.refresh_token);
        setProfile(response.data.profile);
      } catch (error) {
        throw error; // Rethrow the error to be caught in the calling function
      }
    },
    [authenticationAPI, setAccessToken, setRefreshToken, setProfile]
  );

  const state = useMemo(
    () => ({
      rememberMe,
      setRememberMe,
      accountsAPI,
      authenticationAPI,
      configurationAPI,
      conversationsAPI,
      metricsAPI,
      patientsAPI,
      testsAPI,
      utilitiesAPI,
      signIn,
      signOut,
      accessToken,
      refreshToken,
      profile,
    }),
    [
      rememberMe,
      accountsAPI,
      authenticationAPI,
      configurationAPI,
      conversationsAPI,
      metricsAPI,
      patientsAPI,
      testsAPI,
      utilitiesAPI,
      signIn,
      signOut,
      accessToken,
      refreshToken,
      profile,
    ]
  );

  useEffect(() => {
    if (rememberMe) {
      saveState({ accessToken, refreshToken, profile });
    }
  }, [rememberMe, accessToken, refreshToken, profile]);

  return <APIContext.Provider value={state}>{children}</APIContext.Provider>;
};

export default APIProvider;
