import { FC } from "react";

import { Typography } from "@mui/material";

const TermsOfService: FC = () => {
  return (
    <>
      <Typography variant="h6" fontWeight={600}>
        Terms of Service
      </Typography>
      <Typography variant="subtitle1" mt={1}>
        These are the terms of service for using the Koyo application. By using
        this application, you agree to the following terms:
      </Typography>
      <Typography variant="body1" mt={1}>
        <strong>Terms of Use</strong>
      </Typography>
      <Typography variant="body2" mt={1}>
        This page (together with our disclaimer) tells you the terms on which
        you may make use of our website service (“Koyo HealthTech LTD”), whether
        as a guest or a registered user. Please read these terms of use
        carefully before you start to use the service. By using the Website, you
        indicate that you accept these terms of use and that you agree to abide
        by them. If you do not agree to these terms of use, please refrain from
        using the service.
      </Typography>
      <Typography variant="body1" mt={1}>
        <strong>INFORMATION ABOUT US</strong>
      </Typography>
      <Typography variant="body2" mt={1}>
        The service is operated by Koyo HealthTech LTD registered in England and
        Wales with registered number 15478242 registered at King’s Wharf, The
        Quay, Exeter, EX2 4AN).
      </Typography>
      <Typography variant="body1" mt={1}>
        <strong>ACCESSING THE WEBSITE</strong>
      </Typography>
      <Typography variant="body2" mt={1}>
        Access to the Website is permitted on a temporary basis, and we reserve
        the right to withdraw or amend the materials we provide on the Website
        without notice. We will not be liable if for any reason the Website is
        unavailable at any time or for any period. You are responsible for
        making all arrangements necessary for you to have access to our site.
        From time to time, we may restrict access to some parts of the Website.
        If you choose, or you are provided with, a user identification code,
        password or any other piece of information as part of our security
        procedures, you must treat such information as confidential, and you
        must not disclose it to any third party. We have the right to disable
        any user identification code or password, whether chosen by you or
        allocated by us, at any time, if in our opinion you have failed to
        comply with any of the provisions of these terms of use. You are
        responsible for ensuring that all persons who access the service through
        your internet connection are aware of these terms, and that they comply
        with them.
      </Typography>
      <Typography variant="body1" mt={1}>
        <strong>PROHIBITED USES</strong>
      </Typography>
      <Typography variant="body2" mt={1}>
        You may use the Website only for lawful purposes. You may not use the
        Website: In any way that breaches any applicable local, national or
        international law or regulation. In any way that is unlawful or
        fraudulent, or has any unlawful or fraudulent purpose or effect. For the
        purpose of harming or attempting to harm minors in any way. To send,
        knowingly receive, upload, download, use or re-use any material which
        does not comply with our Content Standards set out below. To transmit,
        or procure the sending of, any unsolicited or unauthorised advertising
        or promotional material or any other form of similar solicitation
        (spam). To knowingly transmit any data, send or upload any material that
        contains viruses, Trojan horses, worms, time-bombs, keystroke loggers,
        spyware, adware or any other harmful programs or similar computer code
        designed to adversely affect the operation of any computer software or
        hardware. You also agree: Not to reproduce, duplicate, copy or re-sell
        any part of the Koyo’s website in contravention with these terms of
        website and service use. Not to access without authority, interfere
        with, damage or disrupt: any part of the Website; any equipment or
        network on which the Website is stored; any software used in the
        provision of the Website; or any equipment or network or software owned
        or used by any third party.
      </Typography>
      <Typography variant="body1" mt={1}>
        <strong>INTERACTIVE SERVICES</strong>
      </Typography>
      <Typography variant="body2" mt={1}>
        We may from time to time provide interactive services on the Website.
        Where we do provide any Interactive Service, we will provide clear
        information to you about the kind of service offered, if it is moderated
        and what form of moderation is used (including whether it is human or
        technical). We will do our best to assess any possible risks for users
        (and in particular, for children) from third parties when they use any
        interactive service provided on the Website, and we will decide in each
        case whether it is appropriate to use moderation of the relevant service
        (including what kind of moderation to use) in the light of those risks.
        However, we are under no obligation to oversee, monitor or moderate any
        Interactive Service we provide on the Website, and we expressly exclude
        our liability for any loss or damage arising from the use of any
        Interactive Service by a user in contravention of our Content Standards
        set out below, whether the service is moderated or not. The use of any
        of our Interactive Services by a minor is subject to the consent of
        their parent or guardian. We advise parents who permit their children to
        use an Interactive Service that it is important that they communicate
        with their children about their safety online, as moderation is not
        foolproof. Minors who are using any interactive service should be made
        aware of the potential risks to them. Where we do moderate an
        Interactive Service, we will normally provide you with a means of
        contacting the moderator, should a concern or difficulty arise.
      </Typography>
      <Typography variant="body1" mt={1}>
        <strong>CONTENT STANDARDS</strong>
      </Typography>
      <Typography variant="body2" mt={1}>
        These Content Standards apply to any and all material which you
        contribute to any Interactive Services offered by Koyo HealthTech. You
        must comply with the spirit of the following standards as well as the
        letter. The standards apply to each part of any Contribution as well as
        to its whole. Contributions must be: accurate (where they state facts);
        be genuinely held (where they state opinions); and comply with
        applicable law in the UK and in any country from which they are posted.
        Contributions must not: contain any material which is defamatory of any
        person; contain any material which is obscene, offensive, hateful or
        inflammatory; promote sexually explicit material; promote violence;
        promote discrimination based on race, sex, religion, nationality,
        disability, sexual orientation or age; infringe any copyright, database
        right or trade mark of any other person; be likely to deceive any
        person; be made in breach of any legal duty owed to a third party, such
        as a contractual duty or a duty of confidence; promote any illegal
        activity; be threatening, abuse or invade another’s privacy, or cause
        annoyance, inconvenience or needless anxiety; be likely to harass,
        upset, embarrass, alarm or annoy any other person; be used to
        impersonate any person, or to misrepresent your identity or affiliation
        with any person; give the impression that they emanate from us, if this
        is not the case; advocate, promote or assist any unlawful act such as
        (by way of example only) copyright infringement or computer misuse; or
        misguide any users with incorrect clinical information.
      </Typography>
      <Typography variant="body1" mt={1}>
        <strong>SUSPENSION AND TERMINATION</strong>
      </Typography>
      <Typography variant="body2" mt={1}>
        We will determine, in our discretion, whether there has been a breach of
        these terms of use through your use of the Service. When a breach has
        occurred, we may take such action as we deem appropriate. Failure to
        comply with these terms of use constitutes a material breach of these
        terms of use upon which you are permitted to use the Website, and may
        result in our taking all or any of the following actions: immediate,
        temporary or permanent withdrawal of your right to use the Website;
        immediate, temporary or permanent removal of any posting or material
        uploaded by you to the Website; issue of a warning to you; legal
        proceedings against you for reimbursement of all costs on an indemnity
        basis (including, but not limited to, reasonable administrative and
        legal costs) resulting from the breach; further legal action against
        you; and/or disclosure of such information to law enforcement
        authorities as we reasonably feel is necessary. We exclude liability for
        actions taken in response to breaches of these terms of use. The
        responses described above are not exhaustive, and we may take any other
        action we reasonably deem appropriate.
      </Typography>
      <Typography variant="body1" mt={1}>
        <strong>INTELLECTUAL PROPERTY RIGHTS</strong>
      </Typography>
      <Typography variant="body2" mt={1}>
        We are the owner or the licensee of all intellectual property rights in
        the Website and Service, and in the material published on it. Those
        works are protected by copyright laws and treaties around the world. All
        such rights are reserved. You may print off one copy, and may download
        extracts, of any page(s) from the Website for your personal reference
        and you may draw the attention of others within your organisation to
        material posted on the Website or Service. You must not modify the paper
        or digital copies of any materials you have printed off or downloaded in
        any way, and you must not use any illustrations, photographs, video or
        audio sequences or any graphics separately from any accompanying text.
        Our status (and that of any identified contributors) as the authors of
        material on the Website must always be acknowledged. You must not use
        any part of the materials on the Website for commercial purposes without
        obtaining a licence to do so from us or our licensors. If you print off,
        copy or download any part of the Website in breach of these terms of
        use, your right to use the Website will cease immediately and you must,
        at our option, return or destroy any copies of the materials you have
        made.
      </Typography>
      <Typography variant="body1" mt={1}>
        <strong>RELIANCE ON INFORMATION POSTED</strong>
      </Typography>
      <Typography variant="body2" mt={1}>
        The content on the Website is provided for general information only. It
        is not intended to amount to advice on which you should rely. You must
        consult your GP or other medical adviser to obtain professional or
        specialist advice before taking, or refraining from, any action on the
        basis of the content on Koyo’s service and website.
      </Typography>
      <Typography variant="body1" mt={1}>
        <strong>THE WEBSITE CHANGES REGULARLY</strong>
      </Typography>
      <Typography variant="body2" mt={1}>
        We may update the Service and Website from time to time, and may change
        the content at any time. If the need arises, we may suspend access to
        the Website, or close it indefinitely. Any of the material on the
        Website may be out of date at any given time, and we are under no
        obligation to update such material. We do not guarantee that the
        Website, or any content on it, will be free from errors or omissions.
      </Typography>
      <Typography variant="body1" mt={1}>
        <strong>OUR LIABILITY</strong>
      </Typography>
      <Typography variant="body2" mt={1}>
        Information about you and your visits to the Website: we process
        information about you in accordance with our privacy policy. By using
        the Website, you consent to such processing and you warrant that all
        data provided by you is accurate.
      </Typography>
      <Typography variant="body1" mt={1}>
        <strong>VIRUSES, HACKING AND OTHER OFFENCES</strong>
      </Typography>
      <Typography variant="body2" mt={1}>
        We do not guarantee that the Website will be secure or free from bugs or
        viruses. You are responsible for configuring your information
        technology, computer programmes and platform in order to access the
        Website. You should use your own virus protection software. You must not
        misuse the Website by knowingly introducing viruses, trojans, worms,
        logic bombs or other material which is malicious or technologically
        harmful. You must not attempt to gain unauthorised access to the
        Website, the server on which the Website is stored or any server,
        computer or database connected to the Website. You must not attack the
        Website via a denial-of-service attack or a distributed denial-of
        service attack. By breaching this provision, you would commit a criminal
        offence under the Computer Misuse Act 1990. We will report any such
        breach to the relevant law enforcement authorities and we will
        co-operate with those authorities by disclosing your identity to them.
        In the event of such a breach, your right to use the Website will cease
        immediately. Please refer to our disclaimer for the limitations of our
        liability.
      </Typography>
      <Typography variant="body1" mt={1}>
        <strong>LINKING TO THE WEBSITE</strong>
      </Typography>
      <Typography variant="body2" mt={1}>
        You may link to our home page, provided you do so in a way that is fair
        and legal and does not damage our reputation or take advantage of it,
        but you must not establish a link in such a way as to suggest any form
        of association, approval or endorsement on our part where none exists.
        You must not establish a link from any website that is not owned by you.
        The Website must not be framed on any other site, nor may you create a
        link to any part of the Website other than the home page. We reserve the
        right to withdraw linking permission without notice. The website from
        which you are linking must comply in all respects with the Content
        Standards set out above. If you wish to make any use of material on the
        Website other than that set out above, please address your request to
        the local surgery.
      </Typography>
      <Typography variant="body1" mt={1}>
        <strong>LINKS FROM THE WEBSITE</strong>
      </Typography>
      <Typography variant="body2" mt={1}>
        Where the Website contains links to other sites and resources provided
        by third parties, these links are provided for your information only. We
        have no control over the contents of those sites or resources, and
        accept no responsibility for them or for any loss or damage that may
        arise from your use of them.
      </Typography>
      <Typography variant="body1" mt={1}>
        <strong>JURISDICTION AND APPLICABLE LAW</strong>
      </Typography>
      <Typography variant="body2" mt={1}>
        The English courts will have exclusive jurisdiction over any claim
        arising from, or related to, a visit to the Website although we retain
        the right to bring proceedings against you for breach of these
        conditions in your country of residence or any other relevant country.
        These terms of use and any dispute or claim arising out of or in
        connection with them or their subject matter or formation (including
        non-contractual disputes or claims) shall be governed by and construed
        in accordance with the law of England and Wales.
      </Typography>
      <Typography variant="body1" mt={1}>
        <strong>VARIATIONS</strong>
      </Typography>
      <Typography variant="body2" mt={1}>
        We may revise these terms of use at any time by amending this page. You
        are expected to check this page from time to time to take notice of any
        changes we made, as they are binding on you. Some of the provisions
        contained in these terms of use may also be superseded by provisions or
        notices published elsewhere on the Website.
      </Typography>
      <Typography variant="body1" mt={1}>
        <strong>YOUR CONCERNS</strong>
      </Typography>
      <Typography variant="body2" mt={1}>
        If you have any concerns about material which appears on the Website or
        Koyo Service, please contact your Family Medicine Practitioner, GP or
        other medical adviser.
      </Typography>
      <Typography variant="body2" mt={1}>
        Thank you for visiting Koyo.
      </Typography>
    </>
  );
};

export default TermsOfService;
