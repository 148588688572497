import { FC, useEffect, useState } from "react";

import { Box } from "@mui/material";

import useAPI from "../../hooks/useAPI";

const MessageOfTheDay: FC = () => {
  const { configurationAPI } = useAPI();

  const [message, setMessage] = useState<string>("Welcome!");

  useEffect(() => {
    const fetchMessage = async () => {
      try {
        const response =
          await configurationAPI.readCommunicationsConfiguration();
        const data = await response.data;
        if (data.message_of_the_day) {
          setMessage(data.message_of_the_day);
        } else {
          console.error("message_of_the_day not found in response data");
        }
      } catch (error) {
        console.error("Error fetching message of the day:", error);
      }
    };

    fetchMessage();
  }, [configurationAPI]);

  return (
    <Box display="flex" justifyContent="center">
      {message}
    </Box>
  );
};

export default MessageOfTheDay;
