import { FC, useEffect } from "react";

import { Patient } from "../../api/koyo";
import useAPI from "../../hooks/useAPI";

const FetchAccountPatients: FC<{
  setPatients: (patients: Patient[]) => void;
}> = ({ setPatients }) => {
  const { accountsAPI } = useAPI();

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const response = await accountsAPI.findAccountPatients();
        const data = await response.data;
        setPatients(data);
      } catch (error) {
        console.error("Error fetching account patients:", error);
      }
    };

    fetchPatients();
  }, []);

  return <></>;
};

export default FetchAccountPatients;
