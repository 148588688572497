import { Dispatch, FC, SetStateAction } from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

const LanguageSelector: FC<{
  language: string;
  setLanguage: Dispatch<SetStateAction<string>>;
}> = ({ language, setLanguage }) => {
  const handleChange = (event: SelectChangeEvent) => {
    setLanguage(event.target.value);
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="language-select-label">Language</InputLabel>
      <Select
        labelId="language-select-label"
        id="language-select"
        value={language}
        onChange={handleChange}
        label="Language"
      >
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="fr">French</MenuItem>
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
