import { FC } from "react";

import { Box, Button, Container, Paper, Typography } from "@mui/material";

import SignUpForm from "../components/forms/SignUp";

const CreateAccountPage: FC = () => {
  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Container maxWidth="xs">
        <Box marginBottom={2}>
          <Typography
            variant="h6"
            fontWeight={700}
            align="center"
            gutterBottom
            color="white"
          >
            Sign up here
          </Typography>
          <Typography
            variant="subtitle1"
            fontWeight={500}
            align="center"
            gutterBottom
            color="white"
          >
            Welcome to Koyo!
          </Typography>
          <Paper>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mb={2}
            >
              <Typography variant="body1">Already have an account?</Typography>
              <Button href="/login">Log In</Button>
            </Box>
            <SignUpForm />
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

export default CreateAccountPage;
