import { FC } from "react";

import { Box, Button, Paper } from "@mui/material";
import { ObjectId } from "bson";

import ConsultationPreviews from "../components/chat/ConsultationPreviews";
import NavBar from "../components/navigation/NavBar";
import useAPI from "../hooks/useAPI";

const MyPatientsPage: FC = () => {
  const { profile } = useAPI();

  const accountQuery = { has_active_consultation: true };
  const accountQueryStr = JSON.stringify(accountQuery);
  const conversationQuery = { doctor_id: new ObjectId(profile?._id) };
  const conversationQueryStr = JSON.stringify(conversationQuery);

  return (
    <Box height="100vh" overflow="clip" display="flex" flexDirection="column">
      <Box mt={2} />
      <NavBar />
      <Box height={0.025}></Box>
      <Box flexGrow={1} overflow="auto" display="flex" flexDirection="column">
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Box mb={2} />
          <Button onClick={() => window.history.back()} fullWidth>
            Back
          </Button>
          <ConsultationPreviews
            accountQuery={accountQueryStr}
            conversationQuery={conversationQueryStr}
          />
        </Paper>
        <Box mt={2} />
      </Box>
    </Box>
  );
};

export default MyPatientsPage;
