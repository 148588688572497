import { createContext } from "react";

import {
  AccountsApi,
  AuthenticationApi,
  ConfigurationApi,
  ConversationsApi,
  MetricsApi,
  PatientsApi,
  Profile,
  TestsApi,
  UtilitiesApi,
} from "../api/koyo";

interface APIContextType {
  rememberMe: boolean;
  setRememberMe: (rememberMe: boolean) => void;
  accountsAPI: AccountsApi;
  authenticationAPI: AuthenticationApi;
  configurationAPI: ConfigurationApi;
  conversationsAPI: ConversationsApi;
  metricsAPI: MetricsApi;
  patientsAPI: PatientsApi;
  testsAPI: TestsApi;
  utilitiesAPI: UtilitiesApi;
  signIn: (phone_number: string, password: string) => void;
  signOut: () => void;
  accessToken: string | null;
  refreshToken: string | null;
  profile: Profile | null;
}

const APIContext = createContext<APIContextType | undefined>(undefined);

export default APIContext;
