import { FC } from "react";

import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

import LoadingScreen from "./components/LoadingScreen";
import Main from "./Main";
import useTheme from "./hooks/useTheme";

const App: FC = () => {
  const { theme } = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <LoadingScreen>
        <CssBaseline enableColorScheme />
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </LoadingScreen>
    </ThemeProvider>
  );
};

export default App;
