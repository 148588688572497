import { FC, ReactNode, useEffect, useState } from "react";

import { Box, CircularProgress, Typography } from "@mui/material";

import CONFIG from "../config";
import useTheme from "../hooks/useTheme";

const LoadingScreen: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { theme } = useTheme();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, CONFIG.loadingScreenTime);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <Box
        height="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Box
          position="relative"
          display="flex"
          marginBottom={2}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress sx={{ color: "#FFFFFF" }} size={200} />
          <Typography
            variant="h4"
            color="primary"
            sx={{
              position: "absolute",
            }}
          >
            <Box m="auto">
              <img
                src="images/koyo_logo_transparent.png"
                alt="Koyo"
                width={175}
                height={175}
              />
            </Box>
          </Typography>
        </Box>
      </Box>
    );
  }

  return <>{children}</>;
};

export default LoadingScreen;
