import { FC, useEffect, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Patient, Pricing } from "../api/koyo";
import NavBar from "../components/navigation/NavBar";
import useAPI from "../hooks/useAPI";

const ChatPage: FC = () => {
  const navigate = useNavigate();

  const { accountsAPI, profile } = useAPI();

  useEffect(() => {
    const checkHasActiveConversation = async () => {
      try {
        const response = await accountsAPI.readHasActiveConsultation();
        if (response.data) {
          navigate(`/conversation/${profile!._id}/${response.data.patient_id}`);
        }
      } catch (error) {
        console.error("Error checking active conversation:", error);
      }
    };

    checkHasActiveConversation();
  }, [accountsAPI, navigate, profile]);

  return (
    <Box height="100vh" overflow="clip" display="flex" flexDirection="column">
      <Box mt={2} />
      <NavBar />
      <Box height={0.025}></Box>
      <Box flexGrow={1} overflow="auto" display="flex" flexDirection="column">
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Box mb={2} />
          <Button fullWidth onClick={() => window.history.back()}>
            Back
          </Button>
          <Box mb={2} />
          <Patients />
          <Box display="flex" flexDirection="column" flexGrow={1} />
          <CreditDisplay />
        </Paper>
        <Box mt={2} />
      </Box>
    </Box>
  );
};

const Patients: FC = () => {
  const { accountsAPI } = useAPI();

  const [patients, setPatients] = useState<Patient[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const response = await accountsAPI.findAccountPatients();
        setIsLoading(false);
        setPatients(response.data);
      } catch (error) {
        console.error("Error fetching patients:", error);
      }
    };

    fetchPatients();
  }, [accountsAPI]);

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h6" align="center">
        Please select a family member below to begin a consultation about them
      </Typography>
      {patients.length === 0 && (
        <Box display="flex" justifyContent="center" mt={2}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Typography variant="subtitle1" color={"secondary"}>
              No active conversations
            </Typography>
          )}
        </Box>
      )}
      {patients.map((patient) => (
        <Box key={patient._id!} mt={2}>
          <PatientCard patient={patient} />
        </Box>
      ))}
    </Box>
  );
};

const PatientCard: FC<{
  patient: Patient;
}> = ({ patient }) => {
  const { conversationsAPI, profile } = useAPI();

  const beginConsultation = async () => {
    try {
      await conversationsAPI.activateConversation({
        account_id: profile!._id!,
        patient_id: patient._id!,
      });
      window.location.href = `/conversation/${profile!._id}/${patient._id}`;
    } catch (error) {
      console.error("Error creating consultation:", error);
    }
  };

  return (
    <Button
      variant="outlined"
      sx={{
        textTransform: "none",
        backgroundColor: "white",
        padding: "16px",
        textAlign: "left",
        width: "100%",
        position: "relative",
        borderRadius: "8px",
        overflow: "hidden",
      }}
      onClick={beginConsultation}
    >
      <Box display="flex" flexDirection="row" alignItems="center" width="100%">
        <Box ml={2} flex="1">
          <Typography variant="h6" fontWeight={600}>
            {patient.name}
          </Typography>
        </Box>
      </Box>
    </Button>
  );
};

const CreditDisplay: FC = () => {
  const { accountsAPI, configurationAPI, profile } = useAPI();

  const [credit, setCredit] = useState<number | null>(null);
  const [prices, setPrices] = useState<Pricing | null>(null);

  useEffect(() => {
    const fetchCredit = async () => {
      try {
        const response = await accountsAPI.readAccountCredits();
        setCredit(response.data);
      } catch (error) {
        console.error("Error fetching credit:", error);
      }
    };

    const fetchPrices = async () => {
      try {
        const response = await configurationAPI.readPricingConfiguration();
        setPrices(response.data);
      } catch (error) {
        console.error("Error fetching prices:", error);
      }
    };

    fetchCredit();
    fetchPrices();
  }, [accountsAPI, configurationAPI]);

  if (credit === null || prices === null) {
    return <></>;
  }

  let price = 0.0;
  if (profile?.location === "NG") {
    price = prices.ng / 100.0;
  } else if (profile?.location === "GB") {
    price = prices.uk / 100.0;
  } else if (profile?.location === "ML") {
    price = prices.ml / 100.0;
  }

  let currency = "?";
  if (profile?.location === "NG") {
    currency = "₦";
  } else if (profile?.location === "GB") {
    currency = "£";
  } else if (profile?.location === "ML") {
    currency = "XOF";
  }

  const money = credit * price;

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="center" mb={2}>
        <Typography variant="h6">
          You have {currency}
          {money.toFixed(2)} worth of Koyo credit
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" mb={2}>
        <Typography variant="subtitle1">
          Each consultation spends {currency}
          {price.toFixed(2)} of your credit
        </Typography>
      </Box>
    </Box>
  );
};

export default ChatPage;
