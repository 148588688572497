import { FC, useEffect, useState } from "react";

import { Box, CircularProgress, Typography } from "@mui/material";

import { ConversationPreview } from "../../api/koyo";
import ConversationPreviewCard from "../cards/ConversationPreviewCard";
import useAPI from "../../hooks/useAPI";

const ConsultationPreviews: FC<{
  accountQuery: string;
  conversationQuery: string;
}> = ({ accountQuery, conversationQuery }) => {
  const { conversationsAPI } = useAPI();

  const [conversationPreviews, setConversationPreviews] = useState<
    ConversationPreview[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isFetching = false;
    let fetchTimeout: NodeJS.Timeout | null = null;

    const fetchConversations = async () => {
      if (isFetching) return; // Prevents multiple fetches at the same time

      isFetching = true;

      try {
        const response = await conversationsAPI.findConversationPreviews({
          account_query: accountQuery,
          conversation_query: conversationQuery,
        });
        setConversationPreviews(response.data);
      } catch (error) {
        console.error("Error fetching conversations:", error);
      } finally {
        isFetching = false;
        setIsLoading(false);
      }

      // Set the next fetch to occur 5 seconds after this one finishes
      fetchTimeout = setTimeout(fetchConversations, 5000);
    };

    // Start the initial fetch
    fetchConversations();

    // Clean up when the component unmounts
    return () => {
      if (fetchTimeout) clearTimeout(fetchTimeout);
    };
  }, [conversationsAPI]);

  return (
    <Box display="flex" flexDirection="column">
      {conversationPreviews.length === 0 && (
        <Box display="flex" justifyContent="center" mt={2}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Typography variant="subtitle1" color={"secondary"}>
              No active conversations
            </Typography>
          )}
        </Box>
      )}
      {conversationPreviews.map((preview) => (
        <Box key={preview.user._id} mb={1}>
          <ConversationPreviewCard preview={preview} />
        </Box>
      ))}
    </Box>
  );
};

export default ConsultationPreviews;
