import { FC } from "react";
import { Box, TextField } from "@mui/material";

interface PinInputProps {
  label: string;
  pin: string[];
  setPin: React.Dispatch<React.SetStateAction<string[]>>;
}

const PinInput: FC<PinInputProps> = ({ label, pin, setPin }) => {
  const length = pin.length;

  const handleChange = (index: number, value: string) => {
    if (value.length > 1) return; // Ensure only a single character is input
    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);

    // Move to the next input field if value is not empty
    if (value !== "" && index < length - 1) {
      const nextField = document.getElementById(`pin-input-${index + 1}`);
      nextField?.focus();
    }
  };

  const handleBackspace = (index: number, e: React.KeyboardEvent) => {
    if (e.key === "Backspace" && pin[index] === "" && index > 0) {
      const prevField = document.getElementById(`pin-input-${index - 1}`);
      prevField?.focus();
    }
  };

  return (
    <Box display="flex" justifyContent="center" gap={1}>
      {pin.map((_, index) => (
        <TextField
          label={index === 0 ? label : ""}
          key={index}
          id={`pin-input-${index}`}
          value={pin[index]}
          onChange={(e) => handleChange(index, e.target.value)}
          onKeyDown={(e) => handleBackspace(index, e)}
          inputProps={{
            maxLength: 1,
            style: { textAlign: "center", fontSize: 30 },
          }}
          InputLabelProps={{ shrink: true }}
        />
      ))}
    </Box>
  );
};

export default PinInput;
