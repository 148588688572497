import { FC } from "react";

import { Box, Button, Paper } from "@mui/material";

import NavBar from "../components/navigation/NavBar";
import ProfileForm from "../components/forms/Profile";
import useAPI from "../hooks/useAPI";

const ProfilePage: FC = () => {
  const { signOut } = useAPI();

  const image = "/images/koyo_logo_inline_transparent_orange.png";

  return (
    <Box height="100vh" overflow={"clip"} display="flex" flexDirection="column">
      <Box mt={2} />
      <NavBar />
      <Box height={0.025}></Box>
      <Box
        flexGrow={1}
        overflow="auto"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box
          display="flex"
          justifyContent="center"
          marginTop={-15}
          marginBottom={-15}
        >
          <img
            src={image}
            alt="Koyo Logo"
            style={{ width: "70%", height: "auto" }}
          />
        </Box>
        <Paper>
          <Button fullWidth onClick={() => window.history.back()}>
            Back
          </Button>
          <Box mb={2} />
          <ProfileForm />
          <Box mb={5} />
          <Button
            variant="contained"
            color="primary"
            onClick={signOut}
            style={{ width: "100%", color: "white", fontWeight: 600 }}
          >
            Sign out
          </Button>
        </Paper>
      </Box>
    </Box>
  );
};

export default ProfilePage;
