import { FC, useEffect, useState } from "react";

import { Box, Button, Paper } from "@mui/material";
import axios from "axios";

import CONFIG from "../config";
import GridMenu from "../components/navigation/GridMenu";
import MessageOfTheDay from "../components/configuration/MessageOfTheDay";
import NavBar from "../components/navigation/NavBar";
import useAPI from "../hooks/useAPI";

// Helper function to convert VAPID key
function urlBase64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function arrayBufferToBase64(buffer: ArrayBuffer | null): string {
  if (buffer === null) {
    return ""; // Or handle null appropriately
  }
  let binary = "";
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}

const HomePage: FC = () => {
  const { utilitiesAPI } = useAPI();

  const [pushNotificationsGranted, setPushNotificationsGranted] =
    useState(false);

  const requestNotificationPermission = async () => {
    try {
      if (!("Notification" in window)) {
        throw new Error("Notifications are not supported in this browser.");
      }

      const permission = await Notification.requestPermission();
      setPushNotificationsGranted(permission === "granted");
    } catch (error: any) {
      alert(error.message);
    }
  };

  const subscribeToPushNotifications = async () => {
    if ("serviceWorker" in navigator) {
      const registration = await navigator.serviceWorker.ready;
      try {
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(CONFIG.vapidPublicKey),
        });
        await utilitiesAPI.subscribe({
          endpoint: subscription.endpoint,
          keys: {
            p256dh: arrayBufferToBase64(subscription.getKey("p256dh")),
            auth: arrayBufferToBase64(subscription.getKey("auth")),
          },
        });
        // await utilitiesAPI.subscribe(subscription);
        // await axios.post("/api/subscribe", subscription, {
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // });
      } catch (error) {
        console.error("Error subscribing to push notifications", error);
      }
    }
  };

  useEffect(() => {
    if (pushNotificationsGranted) {
      subscribeToPushNotifications();
    }
  }, [pushNotificationsGranted]);

  return (
    <Box height="100vh" overflow={"clip"} display="flex" flexDirection="column">
      <Box mt={2} />
      <NavBar />
      <Box height={0.025}></Box>
      <Box
        flexGrow={1}
        overflow="auto"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Paper>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <MessageOfTheDay />
            {!pushNotificationsGranted && (
              <>
                <Box mt={4} />
                <Button
                  onClick={requestNotificationPermission}
                  variant="contained"
                >
                  Allow Notifications
                </Button>
              </>
            )}
            <Box mt={4} />
            <GridMenu />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default HomePage;
