/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const AccessLevel = {
    Suspended: 'suspended',
    Standard: 'standard',
    Recruiter: 'recruiter',
    Doctor: 'doctor',
    Supervisor: 'supervisor',
    Admin: 'admin'
} as const;

export type AccessLevel = typeof AccessLevel[keyof typeof AccessLevel];


/**
 * 
 * @export
 * @interface AccessTokenResponse
 */
export interface AccessTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof AccessTokenResponse
     */
    'token_type': string;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenResponse
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenResponse
     */
    'refresh_token': string;
    /**
     * 
     * @type {Profile}
     * @memberof AccessTokenResponse
     */
    'profile': Profile;
}
/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    '_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'referred_by': string | null;
    /**
     * 
     * @type {AccessLevel}
     * @memberof Account
     */
    'access_level': AccessLevel;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'phone_number': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'hashed_password': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'referral_code': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'language': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Account
     */
    'patient_ids'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Account
     */
    'conversation_ids'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    'credits'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    'has_active_consultation'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'logged_in_at'?: string;
}


/**
 * 
 * @export
 * @interface AccountMetricsResponse
 */
export interface AccountMetricsResponse {
    /**
     * 
     * @type {number}
     * @memberof AccountMetricsResponse
     */
    'new_accounts': number;
    /**
     * 
     * @type {number}
     * @memberof AccountMetricsResponse
     */
    'active_accounts': number;
    /**
     * 
     * @type {number}
     * @memberof AccountMetricsResponse
     */
    'total_accounts': number;
}
/**
 * 
 * @export
 * @interface ActivateConversationRequest
 */
export interface ActivateConversationRequest {
    /**
     * 
     * @type {string}
     * @memberof ActivateConversationRequest
     */
    'account_id': string;
    /**
     * 
     * @type {string}
     * @memberof ActivateConversationRequest
     */
    'patient_id': string;
}
/**
 * 
 * @export
 * @interface ClaimAccountRequest
 */
export interface ClaimAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof ClaimAccountRequest
     */
    'phone_number': string;
    /**
     * 
     * @type {string}
     * @memberof ClaimAccountRequest
     */
    'verification_code': string;
}
/**
 * 
 * @export
 * @interface CloseConversationRequest
 */
export interface CloseConversationRequest {
    /**
     * 
     * @type {string}
     * @memberof CloseConversationRequest
     */
    'account_id': string;
}
/**
 * 
 * @export
 * @interface Communications
 */
export interface Communications {
    /**
     * 
     * @type {string}
     * @memberof Communications
     */
    '_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Communications
     */
    'message_of_the_day': string;
}
/**
 * 
 * @export
 * @interface Conversation
 */
export interface Conversation {
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    '_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'doctor_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    'created_at'?: string;
    /**
     * 
     * @type {Array<Message>}
     * @memberof Conversation
     */
    'messages'?: Array<Message>;
    /**
     * 
     * @type {ConversationScore}
     * @memberof Conversation
     */
    'score'?: ConversationScore | null;
}
/**
 * 
 * @export
 * @interface ConversationPreview
 */
export interface ConversationPreview {
    /**
     * 
     * @type {string}
     * @memberof ConversationPreview
     */
    'conversation_id': string;
    /**
     * 
     * @type {Account}
     * @memberof ConversationPreview
     */
    'user': Account;
    /**
     * 
     * @type {Patient}
     * @memberof ConversationPreview
     */
    'patient': Patient;
    /**
     * 
     * @type {Account}
     * @memberof ConversationPreview
     */
    'doctor': Account | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationPreview
     */
    'created_at': string;
    /**
     * 
     * @type {Message}
     * @memberof ConversationPreview
     */
    'last_message': Message | null;
    /**
     * 
     * @type {ConversationScore}
     * @memberof ConversationPreview
     */
    'score': ConversationScore | null;
}
/**
 * 
 * @export
 * @interface ConversationScore
 */
export interface ConversationScore {
    /**
     * 
     * @type {string}
     * @memberof ConversationScore
     */
    'account_id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ConversationScore
     */
    'quality': number | null;
    /**
     * 
     * @type {number}
     * @memberof ConversationScore
     */
    'communication': number | null;
}
/**
 * 
 * @export
 * @interface CreatePatientRequest
 */
export interface CreatePatientRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePatientRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePatientRequest
     */
    'date_of_birth'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreatePatientRequest
     */
    'height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreatePatientRequest
     */
    'weight'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePatientRequest
     */
    'conditions'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePatientRequest
     */
    'medications'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePatientRequest
     */
    'allergies'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreatePatientRequest
     */
    'user_notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePatientRequest
     */
    'doctor_notes'?: string;
}
/**
 * 
 * @export
 * @interface Features
 */
export interface Features {
    /**
     * 
     * @type {string}
     * @memberof Features
     */
    '_id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Features
     */
    'feature_1_enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Features
     */
    'feature_2_enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface FindAccountsRequest
 */
export interface FindAccountsRequest {
    /**
     * 
     * @type {string}
     * @memberof FindAccountsRequest
     */
    'query': string;
}
/**
 * 
 * @export
 * @interface FindConversationPreviewsRequest
 */
export interface FindConversationPreviewsRequest {
    /**
     * 
     * @type {string}
     * @memberof FindConversationPreviewsRequest
     */
    'account_query': string;
    /**
     * 
     * @type {string}
     * @memberof FindConversationPreviewsRequest
     */
    'conversation_query': string;
}
/**
 * 
 * @export
 * @interface FindConversationsRequest
 */
export interface FindConversationsRequest {
    /**
     * 
     * @type {string}
     * @memberof FindConversationsRequest
     */
    'query': string;
}
/**
 * 
 * @export
 * @interface FindPatientsRequest
 */
export interface FindPatientsRequest {
    /**
     * 
     * @type {string}
     * @memberof FindPatientsRequest
     */
    'query': string;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface HasActiveConversationResponse
 */
export interface HasActiveConversationResponse {
    /**
     * 
     * @type {string}
     * @memberof HasActiveConversationResponse
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof HasActiveConversationResponse
     */
    'conversation_id': string;
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'sender_id': string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'created_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof Message
     */
    'score'?: number | null;
}
/**
 * 
 * @export
 * @interface Patient
 */
export interface Patient {
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    '_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'date_of_birth'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Patient
     */
    'height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Patient
     */
    'weight'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Patient
     */
    'conditions'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Patient
     */
    'medications'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Patient
     */
    'allergies'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'user_notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'doctor_notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Patient
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface Pricing
 */
export interface Pricing {
    /**
     * 
     * @type {string}
     * @memberof Pricing
     */
    '_id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Pricing
     */
    'ml': number;
    /**
     * 
     * @type {number}
     * @memberof Pricing
     */
    'ng': number;
    /**
     * 
     * @type {number}
     * @memberof Pricing
     */
    'uk': number;
}
/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'referred_by': string | null;
    /**
     * 
     * @type {AccessLevel}
     * @memberof Profile
     */
    'access_level': AccessLevel;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'phone_number': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'referral_code': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'language': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'updated_at': string;
}


/**
 * 
 * @export
 * @interface ReadConsultationHistoryRequest
 */
export interface ReadConsultationHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof ReadConsultationHistoryRequest
     */
    'user_id': string;
    /**
     * 
     * @type {string}
     * @memberof ReadConsultationHistoryRequest
     */
    'patient_id': string;
}
/**
 * 
 * @export
 * @interface ReadConsultationHistoryResponse
 */
export interface ReadConsultationHistoryResponse {
    /**
     * 
     * @type {Array<Conversation>}
     * @memberof ReadConsultationHistoryResponse
     */
    'conversations': Array<Conversation>;
    /**
     * 
     * @type {boolean}
     * @memberof ReadConsultationHistoryResponse
     */
    'is_active': boolean;
}
/**
 * 
 * @export
 * @interface RefreshAccessTokenRequest
 */
export interface RefreshAccessTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof RefreshAccessTokenRequest
     */
    'refresh_token': string;
}
/**
 * 
 * @export
 * @interface ReserveAccountRequest
 */
export interface ReserveAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof ReserveAccountRequest
     */
    'referred_by'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReserveAccountRequest
     */
    'phone_number': string;
    /**
     * 
     * @type {string}
     * @memberof ReserveAccountRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof ReserveAccountRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ReserveAccountRequest
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof ReserveAccountRequest
     */
    'language': string;
}
/**
 * 
 * @export
 * @interface ScoreConversationRequest
 */
export interface ScoreConversationRequest {
    /**
     * 
     * @type {string}
     * @memberof ScoreConversationRequest
     */
    'conversation_id': string;
    /**
     * 
     * @type {number}
     * @memberof ScoreConversationRequest
     */
    'quality_score': number;
    /**
     * 
     * @type {number}
     * @memberof ScoreConversationRequest
     */
    'communication_score': number;
}
/**
 * 
 * @export
 * @interface SendMessageRequest
 */
export interface SendMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof SendMessageRequest
     */
    'conversation_id': string;
    /**
     * 
     * @type {string}
     * @memberof SendMessageRequest
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    'endpoint': string;
    /**
     * 
     * @type {SubscriptionKeys}
     * @memberof Subscription
     */
    'keys': SubscriptionKeys;
}
/**
 * 
 * @export
 * @interface SubscriptionKeys
 */
export interface SubscriptionKeys {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionKeys
     */
    'p256dh': string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionKeys
     */
    'auth': string;
}
/**
 * 
 * @export
 * @interface UpdateAccountNameRequest
 */
export interface UpdateAccountNameRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountNameRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateAccountPasswordRequest
 */
export interface UpdateAccountPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountPasswordRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface UpdatePatientDoctorNotesRequest
 */
export interface UpdatePatientDoctorNotesRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientDoctorNotesRequest
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientDoctorNotesRequest
     */
    'doctor_notes': string;
}
/**
 * 
 * @export
 * @interface UpdatePatientNameRequest
 */
export interface UpdatePatientNameRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientNameRequest
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientNameRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdatePatientUserNotesRequest
 */
export interface UpdatePatientUserNotesRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientUserNotesRequest
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientUserNotesRequest
     */
    'user_notes': string;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Claim Account
         * @param {ClaimAccountRequest} claimAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimAccount: async (claimAccountRequest: ClaimAccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claimAccountRequest' is not null or undefined
            assertParamExists('claimAccount', 'claimAccountRequest', claimAccountRequest)
            const localVarPath = `/accounts/claim`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(claimAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Account Patients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccountPatients: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Accounts
         * @param {FindAccountsRequest} findAccountsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccounts: async (findAccountsRequest: FindAccountsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findAccountsRequest' is not null or undefined
            assertParamExists('findAccounts', 'findAccountsRequest', findAccountsRequest)
            const localVarPath = `/accounts/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findAccountsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Account Credits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAccountCredits: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/credits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Has Active Consultation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readHasActiveConsultation: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/has-active-consultation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Number Of Referrals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNumberOfReferrals: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/number-of-referrals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate Referral Qr Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReferralQRCode: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/generate_referral_qr_code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reserve Account
         * @param {ReserveAccountRequest} reserveAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reserveAccount: async (reserveAccountRequest: ReserveAccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reserveAccountRequest' is not null or undefined
            assertParamExists('reserveAccount', 'reserveAccountRequest', reserveAccountRequest)
            const localVarPath = `/accounts/reserve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reserveAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Account Name
         * @param {UpdateAccountNameRequest} updateAccountNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountName: async (updateAccountNameRequest: UpdateAccountNameRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAccountNameRequest' is not null or undefined
            assertParamExists('updateAccountName', 'updateAccountNameRequest', updateAccountNameRequest)
            const localVarPath = `/accounts/name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountNameRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Account Password
         * @param {UpdateAccountPasswordRequest} updateAccountPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountPassword: async (updateAccountPasswordRequest: UpdateAccountPasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAccountPasswordRequest' is not null or undefined
            assertParamExists('updateAccountPassword', 'updateAccountPasswordRequest', updateAccountPasswordRequest)
            const localVarPath = `/accounts/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Claim Account
         * @param {ClaimAccountRequest} claimAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claimAccount(claimAccountRequest: ClaimAccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claimAccount(claimAccountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.claimAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find Account Patients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAccountPatients(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Patient>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAccountPatients(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.findAccountPatients']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find Accounts
         * @param {FindAccountsRequest} findAccountsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAccounts(findAccountsRequest: FindAccountsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Account>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAccounts(findAccountsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.findAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Account Credits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAccountCredits(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAccountCredits(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.readAccountCredits']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Has Active Consultation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readHasActiveConsultation(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HasActiveConversationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readHasActiveConsultation(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.readHasActiveConsultation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Number Of Referrals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNumberOfReferrals(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNumberOfReferrals(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.readNumberOfReferrals']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Generate Referral Qr Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readReferralQRCode(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readReferralQRCode(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.readReferralQRCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reserve Account
         * @param {ReserveAccountRequest} reserveAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reserveAccount(reserveAccountRequest: ReserveAccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reserveAccount(reserveAccountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.reserveAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Account Name
         * @param {UpdateAccountNameRequest} updateAccountNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccountName(updateAccountNameRequest: UpdateAccountNameRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccountName(updateAccountNameRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.updateAccountName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Account Password
         * @param {UpdateAccountPasswordRequest} updateAccountPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccountPassword(updateAccountPasswordRequest: UpdateAccountPasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccountPassword(updateAccountPasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.updateAccountPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountsApiFp(configuration)
    return {
        /**
         * 
         * @summary Claim Account
         * @param {ClaimAccountRequest} claimAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimAccount(claimAccountRequest: ClaimAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.claimAccount(claimAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Account Patients
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccountPatients(options?: RawAxiosRequestConfig): AxiosPromise<Array<Patient>> {
            return localVarFp.findAccountPatients(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Accounts
         * @param {FindAccountsRequest} findAccountsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAccounts(findAccountsRequest: FindAccountsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Account>> {
            return localVarFp.findAccounts(findAccountsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Account Credits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAccountCredits(options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.readAccountCredits(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Has Active Consultation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readHasActiveConsultation(options?: RawAxiosRequestConfig): AxiosPromise<HasActiveConversationResponse> {
            return localVarFp.readHasActiveConsultation(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Number Of Referrals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNumberOfReferrals(options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.readNumberOfReferrals(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate Referral Qr Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readReferralQRCode(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.readReferralQRCode(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reserve Account
         * @param {ReserveAccountRequest} reserveAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reserveAccount(reserveAccountRequest: ReserveAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.reserveAccount(reserveAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Account Name
         * @param {UpdateAccountNameRequest} updateAccountNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountName(updateAccountNameRequest: UpdateAccountNameRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.updateAccountName(updateAccountNameRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Account Password
         * @param {UpdateAccountPasswordRequest} updateAccountPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountPassword(updateAccountPasswordRequest: UpdateAccountPasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.updateAccountPassword(updateAccountPasswordRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI {
    /**
     * 
     * @summary Claim Account
     * @param {ClaimAccountRequest} claimAccountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public claimAccount(claimAccountRequest: ClaimAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).claimAccount(claimAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Account Patients
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public findAccountPatients(options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).findAccountPatients(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Accounts
     * @param {FindAccountsRequest} findAccountsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public findAccounts(findAccountsRequest: FindAccountsRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).findAccounts(findAccountsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Account Credits
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public readAccountCredits(options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).readAccountCredits(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Has Active Consultation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public readHasActiveConsultation(options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).readHasActiveConsultation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Number Of Referrals
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public readNumberOfReferrals(options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).readNumberOfReferrals(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate Referral Qr Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public readReferralQRCode(options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).readReferralQRCode(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reserve Account
     * @param {ReserveAccountRequest} reserveAccountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public reserveAccount(reserveAccountRequest: ReserveAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).reserveAccount(reserveAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Account Name
     * @param {UpdateAccountNameRequest} updateAccountNameRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public updateAccountName(updateAccountNameRequest: UpdateAccountNameRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).updateAccountName(updateAccountNameRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Account Password
     * @param {UpdateAccountPasswordRequest} updateAccountPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public updateAccountPassword(updateAccountPasswordRequest: UpdateAccountPasswordRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).updateAccountPassword(updateAccountPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate Access Token
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAccessToken: async (username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('generateAccessToken', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('generateAccessToken', 'password', password)
            const localVarPath = `/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (scope !== undefined) { 
                localVarFormParams.set('scope', scope as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh Access Token
         * @param {RefreshAccessTokenRequest} refreshAccessTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAccessToken: async (refreshAccessTokenRequest: RefreshAccessTokenRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshAccessTokenRequest' is not null or undefined
            assertParamExists('refreshAccessToken', 'refreshAccessTokenRequest', refreshAccessTokenRequest)
            const localVarPath = `/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshAccessTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generate Access Token
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateAccessToken(username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateAccessToken(username, password, grantType, scope, clientId, clientSecret, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.generateAccessToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Refresh Access Token
         * @param {RefreshAccessTokenRequest} refreshAccessTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshAccessToken(refreshAccessTokenRequest: RefreshAccessTokenRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshAccessToken(refreshAccessTokenRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.refreshAccessToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @summary Generate Access Token
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAccessToken(username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<AccessTokenResponse> {
            return localVarFp.generateAccessToken(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh Access Token
         * @param {RefreshAccessTokenRequest} refreshAccessTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAccessToken(refreshAccessTokenRequest: RefreshAccessTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<AccessTokenResponse> {
            return localVarFp.refreshAccessToken(refreshAccessTokenRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @summary Generate Access Token
     * @param {string} username 
     * @param {string} password 
     * @param {string | null} [grantType] 
     * @param {string} [scope] 
     * @param {string | null} [clientId] 
     * @param {string | null} [clientSecret] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public generateAccessToken(username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).generateAccessToken(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh Access Token
     * @param {RefreshAccessTokenRequest} refreshAccessTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public refreshAccessToken(refreshAccessTokenRequest: RefreshAccessTokenRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).refreshAccessToken(refreshAccessTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConfigurationApi - axios parameter creator
 * @export
 */
export const ConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Read Communications Configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCommunicationsConfiguration: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/configuration/communications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Features Configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readFeaturesConfiguration: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/configuration/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Pricing Configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingConfiguration: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/configuration/pricing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationApi - functional programming interface
 * @export
 */
export const ConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Read Communications Configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCommunicationsConfiguration(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Communications>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCommunicationsConfiguration(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigurationApi.readCommunicationsConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Features Configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readFeaturesConfiguration(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Features>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readFeaturesConfiguration(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigurationApi.readFeaturesConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Pricing Configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPricingConfiguration(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pricing>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPricingConfiguration(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigurationApi.readPricingConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConfigurationApi - factory interface
 * @export
 */
export const ConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @summary Read Communications Configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCommunicationsConfiguration(options?: RawAxiosRequestConfig): AxiosPromise<Communications> {
            return localVarFp.readCommunicationsConfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Features Configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readFeaturesConfiguration(options?: RawAxiosRequestConfig): AxiosPromise<Features> {
            return localVarFp.readFeaturesConfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Pricing Configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPricingConfiguration(options?: RawAxiosRequestConfig): AxiosPromise<Pricing> {
            return localVarFp.readPricingConfiguration(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigurationApi - object-oriented interface
 * @export
 * @class ConfigurationApi
 * @extends {BaseAPI}
 */
export class ConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary Read Communications Configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public readCommunicationsConfiguration(options?: RawAxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).readCommunicationsConfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Features Configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public readFeaturesConfiguration(options?: RawAxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).readFeaturesConfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Pricing Configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public readPricingConfiguration(options?: RawAxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).readPricingConfiguration(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConversationsApi - axios parameter creator
 * @export
 */
export const ConversationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate Conversation
         * @param {ActivateConversationRequest} activateConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateConversation: async (activateConversationRequest: ActivateConversationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activateConversationRequest' is not null or undefined
            assertParamExists('activateConversation', 'activateConversationRequest', activateConversationRequest)
            const localVarPath = `/conversations/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activateConversationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Close Conversation
         * @param {CloseConversationRequest} closeConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeConversation: async (closeConversationRequest: CloseConversationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'closeConversationRequest' is not null or undefined
            assertParamExists('closeConversation', 'closeConversationRequest', closeConversationRequest)
            const localVarPath = `/conversations/close`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(closeConversationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Conversation Previews
         * @param {FindConversationPreviewsRequest} findConversationPreviewsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findConversationPreviews: async (findConversationPreviewsRequest: FindConversationPreviewsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findConversationPreviewsRequest' is not null or undefined
            assertParamExists('findConversationPreviews', 'findConversationPreviewsRequest', findConversationPreviewsRequest)
            const localVarPath = `/conversations/previews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findConversationPreviewsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Conversations
         * @param {FindConversationsRequest} findConversationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findConversations: async (findConversationsRequest: FindConversationsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findConversationsRequest' is not null or undefined
            assertParamExists('findConversations', 'findConversationsRequest', findConversationsRequest)
            const localVarPath = `/conversations/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findConversationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Consultation History
         * @param {ReadConsultationHistoryRequest} readConsultationHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readConsultationHistory: async (readConsultationHistoryRequest: ReadConsultationHistoryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readConsultationHistoryRequest' is not null or undefined
            assertParamExists('readConsultationHistory', 'readConsultationHistoryRequest', readConsultationHistoryRequest)
            const localVarPath = `/conversations/read-consultation-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(readConsultationHistoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Score Conversation
         * @param {ScoreConversationRequest} scoreConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scoreConversation: async (scoreConversationRequest: ScoreConversationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scoreConversationRequest' is not null or undefined
            assertParamExists('scoreConversation', 'scoreConversationRequest', scoreConversationRequest)
            const localVarPath = `/conversations/score`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scoreConversationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send Message
         * @param {SendMessageRequest} sendMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage: async (sendMessageRequest: SendMessageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendMessageRequest' is not null or undefined
            assertParamExists('sendMessage', 'sendMessageRequest', sendMessageRequest)
            const localVarPath = `/conversations/send_message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConversationsApi - functional programming interface
 * @export
 */
export const ConversationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConversationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activate Conversation
         * @param {ActivateConversationRequest} activateConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateConversation(activateConversationRequest: ActivateConversationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateConversation(activateConversationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConversationsApi.activateConversation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Close Conversation
         * @param {CloseConversationRequest} closeConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async closeConversation(closeConversationRequest: CloseConversationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.closeConversation(closeConversationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConversationsApi.closeConversation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find Conversation Previews
         * @param {FindConversationPreviewsRequest} findConversationPreviewsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findConversationPreviews(findConversationPreviewsRequest: FindConversationPreviewsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConversationPreview>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findConversationPreviews(findConversationPreviewsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConversationsApi.findConversationPreviews']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find Conversations
         * @param {FindConversationsRequest} findConversationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findConversations(findConversationsRequest: FindConversationsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Conversation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findConversations(findConversationsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConversationsApi.findConversations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Read Consultation History
         * @param {ReadConsultationHistoryRequest} readConsultationHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readConsultationHistory(readConsultationHistoryRequest: ReadConsultationHistoryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReadConsultationHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readConsultationHistory(readConsultationHistoryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConversationsApi.readConsultationHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Score Conversation
         * @param {ScoreConversationRequest} scoreConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scoreConversation(scoreConversationRequest: ScoreConversationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scoreConversation(scoreConversationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConversationsApi.scoreConversation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Send Message
         * @param {SendMessageRequest} sendMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMessage(sendMessageRequest: SendMessageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendMessage(sendMessageRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConversationsApi.sendMessage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConversationsApi - factory interface
 * @export
 */
export const ConversationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConversationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Activate Conversation
         * @param {ActivateConversationRequest} activateConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateConversation(activateConversationRequest: ActivateConversationRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.activateConversation(activateConversationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Close Conversation
         * @param {CloseConversationRequest} closeConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeConversation(closeConversationRequest: CloseConversationRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.closeConversation(closeConversationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Conversation Previews
         * @param {FindConversationPreviewsRequest} findConversationPreviewsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findConversationPreviews(findConversationPreviewsRequest: FindConversationPreviewsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ConversationPreview>> {
            return localVarFp.findConversationPreviews(findConversationPreviewsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Conversations
         * @param {FindConversationsRequest} findConversationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findConversations(findConversationsRequest: FindConversationsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Conversation>> {
            return localVarFp.findConversations(findConversationsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Consultation History
         * @param {ReadConsultationHistoryRequest} readConsultationHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readConsultationHistory(readConsultationHistoryRequest: ReadConsultationHistoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReadConsultationHistoryResponse> {
            return localVarFp.readConsultationHistory(readConsultationHistoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Score Conversation
         * @param {ScoreConversationRequest} scoreConversationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scoreConversation(scoreConversationRequest: ScoreConversationRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.scoreConversation(scoreConversationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send Message
         * @param {SendMessageRequest} sendMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage(sendMessageRequest: SendMessageRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.sendMessage(sendMessageRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConversationsApi - object-oriented interface
 * @export
 * @class ConversationsApi
 * @extends {BaseAPI}
 */
export class ConversationsApi extends BaseAPI {
    /**
     * 
     * @summary Activate Conversation
     * @param {ActivateConversationRequest} activateConversationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public activateConversation(activateConversationRequest: ActivateConversationRequest, options?: RawAxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).activateConversation(activateConversationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Close Conversation
     * @param {CloseConversationRequest} closeConversationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public closeConversation(closeConversationRequest: CloseConversationRequest, options?: RawAxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).closeConversation(closeConversationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Conversation Previews
     * @param {FindConversationPreviewsRequest} findConversationPreviewsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public findConversationPreviews(findConversationPreviewsRequest: FindConversationPreviewsRequest, options?: RawAxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).findConversationPreviews(findConversationPreviewsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Conversations
     * @param {FindConversationsRequest} findConversationsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public findConversations(findConversationsRequest: FindConversationsRequest, options?: RawAxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).findConversations(findConversationsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Consultation History
     * @param {ReadConsultationHistoryRequest} readConsultationHistoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public readConsultationHistory(readConsultationHistoryRequest: ReadConsultationHistoryRequest, options?: RawAxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).readConsultationHistory(readConsultationHistoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Score Conversation
     * @param {ScoreConversationRequest} scoreConversationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public scoreConversation(scoreConversationRequest: ScoreConversationRequest, options?: RawAxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).scoreConversation(scoreConversationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send Message
     * @param {SendMessageRequest} sendMessageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationsApi
     */
    public sendMessage(sendMessageRequest: SendMessageRequest, options?: RawAxiosRequestConfig) {
        return ConversationsApiFp(this.configuration).sendMessage(sendMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MetricsApi - axios parameter creator
 * @export
 */
export const MetricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary New Accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newAccounts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/metrics/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetricsApi - functional programming interface
 * @export
 */
export const MetricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetricsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary New Accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newAccounts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountMetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newAccounts(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.newAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MetricsApi - factory interface
 * @export
 */
export const MetricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetricsApiFp(configuration)
    return {
        /**
         * 
         * @summary New Accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newAccounts(options?: RawAxiosRequestConfig): AxiosPromise<AccountMetricsResponse> {
            return localVarFp.newAccounts(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetricsApi - object-oriented interface
 * @export
 * @class MetricsApi
 * @extends {BaseAPI}
 */
export class MetricsApi extends BaseAPI {
    /**
     * 
     * @summary New Accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public newAccounts(options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).newAccounts(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PatientsApi - axios parameter creator
 * @export
 */
export const PatientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Patient
         * @param {CreatePatientRequest} createPatientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPatient: async (createPatientRequest: CreatePatientRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPatientRequest' is not null or undefined
            assertParamExists('createPatient', 'createPatientRequest', createPatientRequest)
            const localVarPath = `/patients/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPatientRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find Patients
         * @param {FindPatientsRequest} findPatientsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPatients: async (findPatientsRequest: FindPatientsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findPatientsRequest' is not null or undefined
            assertParamExists('findPatients', 'findPatientsRequest', findPatientsRequest)
            const localVarPath = `/patients/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findPatientsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Patient Doctor Notes
         * @param {UpdatePatientDoctorNotesRequest} updatePatientDoctorNotesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientDoctorNotes: async (updatePatientDoctorNotesRequest: UpdatePatientDoctorNotesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePatientDoctorNotesRequest' is not null or undefined
            assertParamExists('updatePatientDoctorNotes', 'updatePatientDoctorNotesRequest', updatePatientDoctorNotesRequest)
            const localVarPath = `/patients/doctor_notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePatientDoctorNotesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Patient Name
         * @param {UpdatePatientNameRequest} updatePatientNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientName: async (updatePatientNameRequest: UpdatePatientNameRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePatientNameRequest' is not null or undefined
            assertParamExists('updatePatientName', 'updatePatientNameRequest', updatePatientNameRequest)
            const localVarPath = `/patients/name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePatientNameRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Patient User Notes
         * @param {UpdatePatientUserNotesRequest} updatePatientUserNotesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientUserNotes: async (updatePatientUserNotesRequest: UpdatePatientUserNotesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePatientUserNotesRequest' is not null or undefined
            assertParamExists('updatePatientUserNotes', 'updatePatientUserNotesRequest', updatePatientUserNotesRequest)
            const localVarPath = `/patients/user_notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePatientUserNotesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientsApi - functional programming interface
 * @export
 */
export const PatientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Patient
         * @param {CreatePatientRequest} createPatientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPatient(createPatientRequest: CreatePatientRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPatient(createPatientRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientsApi.createPatient']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find Patients
         * @param {FindPatientsRequest} findPatientsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPatients(findPatientsRequest: FindPatientsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Patient>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPatients(findPatientsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientsApi.findPatients']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Patient Doctor Notes
         * @param {UpdatePatientDoctorNotesRequest} updatePatientDoctorNotesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePatientDoctorNotes(updatePatientDoctorNotesRequest: UpdatePatientDoctorNotesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePatientDoctorNotes(updatePatientDoctorNotesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientsApi.updatePatientDoctorNotes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Patient Name
         * @param {UpdatePatientNameRequest} updatePatientNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePatientName(updatePatientNameRequest: UpdatePatientNameRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePatientName(updatePatientNameRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientsApi.updatePatientName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Patient User Notes
         * @param {UpdatePatientUserNotesRequest} updatePatientUserNotesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePatientUserNotes(updatePatientUserNotesRequest: UpdatePatientUserNotesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePatientUserNotes(updatePatientUserNotesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientsApi.updatePatientUserNotes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PatientsApi - factory interface
 * @export
 */
export const PatientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Patient
         * @param {CreatePatientRequest} createPatientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPatient(createPatientRequest: CreatePatientRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createPatient(createPatientRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find Patients
         * @param {FindPatientsRequest} findPatientsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPatients(findPatientsRequest: FindPatientsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Patient>> {
            return localVarFp.findPatients(findPatientsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Patient Doctor Notes
         * @param {UpdatePatientDoctorNotesRequest} updatePatientDoctorNotesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientDoctorNotes(updatePatientDoctorNotesRequest: UpdatePatientDoctorNotesRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.updatePatientDoctorNotes(updatePatientDoctorNotesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Patient Name
         * @param {UpdatePatientNameRequest} updatePatientNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientName(updatePatientNameRequest: UpdatePatientNameRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.updatePatientName(updatePatientNameRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Patient User Notes
         * @param {UpdatePatientUserNotesRequest} updatePatientUserNotesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientUserNotes(updatePatientUserNotesRequest: UpdatePatientUserNotesRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.updatePatientUserNotes(updatePatientUserNotesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientsApi - object-oriented interface
 * @export
 * @class PatientsApi
 * @extends {BaseAPI}
 */
export class PatientsApi extends BaseAPI {
    /**
     * 
     * @summary Create Patient
     * @param {CreatePatientRequest} createPatientRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public createPatient(createPatientRequest: CreatePatientRequest, options?: RawAxiosRequestConfig) {
        return PatientsApiFp(this.configuration).createPatient(createPatientRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find Patients
     * @param {FindPatientsRequest} findPatientsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public findPatients(findPatientsRequest: FindPatientsRequest, options?: RawAxiosRequestConfig) {
        return PatientsApiFp(this.configuration).findPatients(findPatientsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Patient Doctor Notes
     * @param {UpdatePatientDoctorNotesRequest} updatePatientDoctorNotesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public updatePatientDoctorNotes(updatePatientDoctorNotesRequest: UpdatePatientDoctorNotesRequest, options?: RawAxiosRequestConfig) {
        return PatientsApiFp(this.configuration).updatePatientDoctorNotes(updatePatientDoctorNotesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Patient Name
     * @param {UpdatePatientNameRequest} updatePatientNameRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public updatePatientName(updatePatientNameRequest: UpdatePatientNameRequest, options?: RawAxiosRequestConfig) {
        return PatientsApiFp(this.configuration).updatePatientName(updatePatientNameRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Patient User Notes
     * @param {UpdatePatientUserNotesRequest} updatePatientUserNotesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientsApi
     */
    public updatePatientUserNotes(updatePatientUserNotesRequest: UpdatePatientUserNotesRequest, options?: RawAxiosRequestConfig) {
        return PatientsApiFp(this.configuration).updatePatientUserNotes(updatePatientUserNotesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TestsApi - axios parameter creator
 * @export
 */
export const TestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Ping the server to check if it is running.
         * @summary Ping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingAPI: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ping the database, via the API, to check if it is running.
         * @summary Ping Cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingCache: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ping-cache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ping the database, via the API, to check if it is running.
         * @summary Ping Database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingDatabase: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ping-database`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestsApi - functional programming interface
 * @export
 */
export const TestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestsApiAxiosParamCreator(configuration)
    return {
        /**
         * Ping the server to check if it is running.
         * @summary Ping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingAPI(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingAPI(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestsApi.pingAPI']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Ping the database, via the API, to check if it is running.
         * @summary Ping Cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingCache(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingCache(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestsApi.pingCache']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Ping the database, via the API, to check if it is running.
         * @summary Ping Database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingDatabase(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingDatabase(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestsApi.pingDatabase']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TestsApi - factory interface
 * @export
 */
export const TestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestsApiFp(configuration)
    return {
        /**
         * Ping the server to check if it is running.
         * @summary Ping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingAPI(options?: RawAxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.pingAPI(options).then((request) => request(axios, basePath));
        },
        /**
         * Ping the database, via the API, to check if it is running.
         * @summary Ping Cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingCache(options?: RawAxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.pingCache(options).then((request) => request(axios, basePath));
        },
        /**
         * Ping the database, via the API, to check if it is running.
         * @summary Ping Database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingDatabase(options?: RawAxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.pingDatabase(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestsApi - object-oriented interface
 * @export
 * @class TestsApi
 * @extends {BaseAPI}
 */
export class TestsApi extends BaseAPI {
    /**
     * Ping the server to check if it is running.
     * @summary Ping
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestsApi
     */
    public pingAPI(options?: RawAxiosRequestConfig) {
        return TestsApiFp(this.configuration).pingAPI(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ping the database, via the API, to check if it is running.
     * @summary Ping Cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestsApi
     */
    public pingCache(options?: RawAxiosRequestConfig) {
        return TestsApiFp(this.configuration).pingCache(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ping the database, via the API, to check if it is running.
     * @summary Ping Database
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestsApi
     */
    public pingDatabase(options?: RawAxiosRequestConfig) {
        return TestsApiFp(this.configuration).pingDatabase(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilitiesApi - axios parameter creator
 * @export
 */
export const UtilitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Clear the cache.
         * @summary Clear Cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCache: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clear-cache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send a push notification.
         * @summary Send Notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/send-notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Subscribe to push notifications.
         * @summary Subscribe
         * @param {Subscription} subscription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribe: async (subscription: Subscription, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscription' is not null or undefined
            assertParamExists('subscribe', 'subscription', subscription)
            const localVarPath = `/subscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Wipe the database.
         * @summary Wipe Database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wipeDatabase: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wipe-database`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilitiesApi - functional programming interface
 * @export
 */
export const UtilitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * Clear the cache.
         * @summary Clear Cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearCache(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearCache(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilitiesApi.clearCache']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Send a push notification.
         * @summary Send Notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNotification(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNotification(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilitiesApi.sendNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Subscribe to push notifications.
         * @summary Subscribe
         * @param {Subscription} subscription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscribe(subscription: Subscription, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscribe(subscription, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilitiesApi.subscribe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Wipe the database.
         * @summary Wipe Database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wipeDatabase(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wipeDatabase(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilitiesApi.wipeDatabase']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilitiesApi - factory interface
 * @export
 */
export const UtilitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilitiesApiFp(configuration)
    return {
        /**
         * Clear the cache.
         * @summary Clear Cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCache(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.clearCache(options).then((request) => request(axios, basePath));
        },
        /**
         * Send a push notification.
         * @summary Send Notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.sendNotification(options).then((request) => request(axios, basePath));
        },
        /**
         * Subscribe to push notifications.
         * @summary Subscribe
         * @param {Subscription} subscription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribe(subscription: Subscription, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.subscribe(subscription, options).then((request) => request(axios, basePath));
        },
        /**
         * Wipe the database.
         * @summary Wipe Database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wipeDatabase(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.wipeDatabase(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilitiesApi - object-oriented interface
 * @export
 * @class UtilitiesApi
 * @extends {BaseAPI}
 */
export class UtilitiesApi extends BaseAPI {
    /**
     * Clear the cache.
     * @summary Clear Cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public clearCache(options?: RawAxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).clearCache(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send a push notification.
     * @summary Send Notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public sendNotification(options?: RawAxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).sendNotification(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Subscribe to push notifications.
     * @summary Subscribe
     * @param {Subscription} subscription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public subscribe(subscription: Subscription, options?: RawAxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).subscribe(subscription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Wipe the database.
     * @summary Wipe Database
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitiesApi
     */
    public wipeDatabase(options?: RawAxiosRequestConfig) {
        return UtilitiesApiFp(this.configuration).wipeDatabase(options).then((request) => request(this.axios, this.basePath));
    }
}



