import { FC, useState } from "react";

import { Box, Button, TextField } from "@mui/material";

import useAPI from "../../hooks/useAPI";

const CreatePatientForm: FC<{
  setNewPatientModal: (value: boolean) => void;
}> = ({ setNewPatientModal }) => {
  const { patientsAPI } = useAPI();

  const [patientName, setPatientName] = useState("");

  const createPatient = async () => {
    try {
      const response = await patientsAPI.createPatient({
        name: patientName,
      });

      console.log(response);
      setNewPatientModal(false);
    } catch (error) {
      console.error("Error creating patient:", error);
    }
  };

  return (
    <>
      <TextField
        label="Name"
        fullWidth
        value={patientName}
        onChange={(e) => setPatientName(e.target.value)}
      />
      <Box mb={2} />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={createPatient}
      >
        Create
      </Button>
    </>
  );
};

export default CreatePatientForm;
