import { FC } from "react";

import { Box, Button, Paper } from "@mui/material";

import ConsultationPreviews from "../components/chat/ConsultationPreviews";
import NavBar from "../components/navigation/NavBar";

const AllConsultationsPage: FC = () => {
  const accountQuery = {};
  const accountQueryStr = JSON.stringify(accountQuery);
  const conversationQuery = {};
  const conversationQueryStr = JSON.stringify(conversationQuery);

  return (
    <Box height="100vh" overflow="clip" display="flex" flexDirection="column">
      <Box mt={2} />
      <NavBar />
      <Box height={0.025}></Box>
      <Box flexGrow={1} overflow="auto" display="flex" flexDirection="column">
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Box mb={2} />
          <Button onClick={() => window.history.back()} fullWidth>
            Back
          </Button>
          <ConsultationPreviews
            accountQuery={accountQueryStr}
            conversationQuery={conversationQueryStr}
          />
        </Paper>
        <Box mt={2} />
      </Box>
    </Box>
  );
};

export default AllConsultationsPage;
