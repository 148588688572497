import { FC, useEffect, useState } from "react";

import { Box, Button, Paper, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import { Conversation } from "../api/koyo";
import ChatInput from "../components/chat/MessageInput";
import ConversationDisplay from "../components/ConversationDisplay";
import NavBar from "../components/navigation/NavBar";
import useAPI from "../hooks/useAPI";

const ConversationPage: FC = () => {
  const { userId, patientId } = useParams();

  const { conversationsAPI } = useAPI();

  const [conversationHistory, setConversationHistory] = useState<
    Conversation[]
  >([]);
  const [hasActiveConversation, setHasActiveConversation] = useState(false);

  const sendMessage = async (content: string) => {
    const numConversations = conversationHistory.length;
    if (numConversations === 0) {
      console.error("No conversations in history");
      return;
    }

    const conversationId = conversationHistory[numConversations - 1]._id;
    try {
      await conversationsAPI.sendMessage({
        conversation_id: conversationId!,
        content: content,
      });
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const closeConversation = async () => {
    try {
      await conversationsAPI.closeConversation({
        account_id: userId!,
      });
    } catch (error) {
      console.error("Error terminating conversation:", error);
    }
  };

  useEffect(() => {
    const fetchConversationHistory = async () => {
      if (!userId || !patientId) {
        console.error("Missing user ID or patient ID");
        return;
      }

      try {
        const response = await conversationsAPI.readConsultationHistory({
          user_id: userId!,
          patient_id: patientId!,
        });
        setConversationHistory(response.data.conversations);
        setHasActiveConversation(response.data.is_active);
      } catch (error) {
        console.error("Error fetching conversation history:", error);
      }
    };
    fetchConversationHistory();
    setInterval(fetchConversationHistory, 3000);
  }, [userId, patientId, conversationsAPI]);

  return (
    <Box height="100vh" overflow="clip" display="flex" flexDirection="column">
      <Box mt={2} />
      <NavBar />
      <Box height={0.025}></Box>
      <Box flexGrow={1} overflow="auto" display="flex" flexDirection="column">
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Button fullWidth onClick={() => window.history.back()}>
            Back
          </Button>
          {conversationHistory.map((conversation) => (
            <ConversationDisplay
              key={conversation._id}
              conversation={conversation}
            />
          ))}
          <Box mb={2} />
          {hasActiveConversation ? null : (
            <Box display="flex" justifyContent="center">
              <Typography variant="h6">Conversation inactive</Typography>
            </Box>
          )}
        </Paper>
        <Box mt={2} />
        <Paper sx={{ p: 2 }}>
          <ChatInput
            sendMessage={sendMessage}
            closeConversation={closeConversation}
          />
        </Paper>
        <Box mt={2} />
      </Box>
    </Box>
  );
};

export default ConversationPage;
