import { FC, useEffect, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";

import NavBar from "../components/navigation/NavBar";
import useAPI from "../hooks/useAPI";

const RecruitPage: FC = () => {
  return (
    <Box height="100vh" overflow={"clip"} display="flex" flexDirection="column">
      <Box mt={2} />
      <NavBar />
      <Box height={0.025}></Box>
      <Box
        flexGrow={1}
        overflow="auto"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Paper>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Button fullWidth onClick={() => window.history.back()}>
              Back
            </Button>
            <Box mb={2} />
            <NumberOfReferrals />
            <Box mb={2} />
            <QRCode />
            <Box mb={4} />
            <ReferralCode />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

const NumberOfReferrals: FC = () => {
  const { accountsAPI } = useAPI();

  const [referralCount, setReferralCount] = useState<number | null>(null);

  useEffect(() => {
    const fetchReferralCount = async () => {
      try {
        const response = await accountsAPI.readNumberOfReferrals();
        console.log(response.data);
        setReferralCount(response.data);
      } catch (error) {
        console.error("Error fetching referral count:", error);
      }
    };

    fetchReferralCount();
  });

  if (referralCount === null) {
    return (
      <Box display="flex" justifyContent="center">
        <Typography variant="h5">
          Number of referrals: {referralCount}
        </Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" justifyContent="center">
      <Typography variant="h5">Number of referrals: {referralCount}</Typography>
    </Box>
  );
};

const QRCode: FC = () => {
  const { accountsAPI } = useAPI();

  const [qrCode, setQRCode] = useState<string | null>(null);

  useEffect(() => {
    const fetchQRCode = async () => {
      try {
        const response = await accountsAPI.readReferralQRCode({
          responseType: "blob", // Set the response type to 'blob'
        });
        const reader = new FileReader();
        reader.onloadend = () => {
          setQRCode(reader.result as string); // Set the base64 encoded string
        };
        reader.readAsDataURL(response.data); // Read the blob data as a data URL
      } catch (error) {
        console.error("Error fetching QR code:", error);
      }
    };

    fetchQRCode();
  }, [accountsAPI]);

  if (!qrCode) {
    return <CircularProgress />;
  }

  return (
    <Box display="flex" justifyContent="center">
      <img
        src={qrCode}
        alt="QR code"
        width={400}
        height={400}
        style={{ borderRadius: 5 }}
      />
    </Box>
  );
};

const ReferralCode: FC = () => {
  const { profile } = useAPI();

  return (
    <Box display="flex" justifyContent="center">
      <Typography variant="h2" fontWeight={600}>
        {profile?.referral_code}
      </Typography>
    </Box>
  );
};

export default RecruitPage;
