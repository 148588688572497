import { FC } from "react";

import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { ConversationPreview } from "../../api/koyo";

const ConversationPreviewCard: FC<{
  preview: ConversationPreview;
}> = ({ preview }) => {
  const navigate = useNavigate();

  return (
    <Button
      variant="outlined"
      sx={{
        textTransform: "none",
        backgroundColor: "white",
        padding: "16px",
        textAlign: "left",
        width: "100%",
        position: "relative",
        borderRadius: "8px",
        overflow: "hidden",
      }}
      onClick={() =>
        navigate(`/conversation/${preview.user._id}/${preview.patient._id}`)
      }
    >
      <Box display="flex" flexDirection="row" alignItems="center" width="100%">
        <Box ml={2} flex="1">
          <Typography variant="h6" fontWeight={600}>
            {preview.user.name}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary" mt={1}>
            {preview.last_message?.content}
          </Typography>
        </Box>
      </Box>
    </Button>
  );
};

export default ConversationPreviewCard;
