import { FC, useEffect, useState } from "react";

import { Box, Button, Paper, Typography } from "@mui/material";

import NavBar from "../components/navigation/NavBar";
import useAPI from "../hooks/useAPI";

const MetricsPage: FC = () => {
  const { metricsAPI } = useAPI();

  const [newAccounts, setNewAccounts] = useState(0);
  const [activeAccounts, setActiveAccounts] = useState(0);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const response = await metricsAPI.newAccounts();
        setNewAccounts(response.data.new_accounts);
        setActiveAccounts(response.data.active_accounts);
      } catch (error) {
        console.error("Error fetching metrics:", error);
      }
    };

    fetchMetrics();
  }, [metricsAPI]);

  return (
    <Box height="100vh" overflow="clip" display="flex" flexDirection="column">
      <Box mt={2} />
      <NavBar />
      <Box height={0.025}></Box>
      <Box flexGrow={1} overflow="auto" display="flex" flexDirection="column">
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Box mb={2} />
          <Button fullWidth onClick={() => window.history.back()}>
            Back
          </Button>
          <Box mb={2} />
          <Typography variant="h4">New accounts: {newAccounts}</Typography>
          <Typography variant="h4">
            Active accounts: {activeAccounts}
          </Typography>
        </Paper>
        <Box mt={2} />
      </Box>
    </Box>
  );
};

export default MetricsPage;
