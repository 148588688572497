import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import APIProvider from "./providers/APIProvider";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ThemeProvider from "./providers/ThemeProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <APIProvider>
        <App />
      </APIProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// Register the service worker
serviceWorkerRegistration.register();

// If we want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
