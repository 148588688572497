import { FC, useState } from "react";

import { Divider, Grid, Paper, Typography } from "@mui/material";
import { format } from "date-fns";

import { Patient } from "../../api/koyo";

const PatientCard: FC<{ patient: Patient }> = ({ patient }) => {
  const [detailsVisible, setDetailsVisible] = useState(false);

  const toggleDetails = () => {
    setDetailsVisible(!detailsVisible);
  };

  return (
    <Paper sx={{ padding: 2 }}>
      <Typography
        variant="h6"
        gutterBottom
        onClick={toggleDetails}
        sx={{ cursor: "pointer" }} // Add cursor pointer to indicate clickable area
      >
        Patient: {patient.name}
      </Typography>
      <Divider />
      {detailsVisible && (
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              Date of Birth:
            </Typography>
            <Typography>
              {patient.date_of_birth
                ? format(new Date(patient.date_of_birth), "do MMMM yyyy")
                : "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              Height:
            </Typography>
            <Typography>
              {patient.height ? `${patient.height} cm` : "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              Weight:
            </Typography>
            <Typography>
              {patient.weight ? `${patient.weight} g` : "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              Conditions:
            </Typography>
            <Typography>
              {patient.conditions ? patient.conditions.join(", ") : "None"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              Medications:
            </Typography>
            <Typography>
              {patient.medications ? patient.medications.join(", ") : "None"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              Allergies:
            </Typography>
            <Typography>
              {patient.allergies ? patient.allergies.join(", ") : "None"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              User Notes:
            </Typography>
            <Typography>{patient.user_notes || "None"}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              Doctor Notes:
            </Typography>
            <Typography>{patient.doctor_notes || "None"}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              Created At:
            </Typography>
            <Typography>
              {format(new Date(patient.created_at!), "do MMMM yyyy")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              Updated At:
            </Typography>
            <Typography>
              {format(new Date(patient.updated_at!), "do MMMM yyyy")}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default PatientCard;
