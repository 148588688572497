import { KeyboardEvent, useState } from "react";

import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import ROUTES from "./../../routes";
import useAPI from "./../../hooks/useAPI";

export default function NavBar() {
  const navigate = useNavigate();

  const { profile } = useAPI();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const goHome = () => {
    navigate("/");
  };
  const goProfile = () => {
    navigate("/profile");
  };

  const toggleDrawer =
    (open: boolean) => (event: KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as KeyboardEvent).key === "Tab" ||
          (event as KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const list = () => (
    <List>
      {ROUTES.map((route) => (
        <ListItem
          button
          key={route.path}
          onClick={() => {
            toggleDrawer(false);
            navigate(`/${route.path}`);
          }}
        >
          <ListItemIcon>{route.icon}</ListItemIcon>
          <ListItemText
            primary={route.label}
            primaryTypographyProps={{
              style: { fontWeight: 600 },
            }}
          />
        </ListItem>
      ))}
    </List>
  );

  return (
    <div>
      <AppBar
        position="static"
        sx={{ minHeight: 0, height: 0, backgroundColor: "#824644" }}
      >
        <Toolbar variant="dense" sx={{ minHeight: 0 }}>
          <Box width="100%" display="flex" justifyContent="space-between">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={goHome}
            >
              <img
                src="/images/koyo_logo_transparent.png"
                alt="Koyo"
                height="80px"
                width="80px"
              />
            </IconButton>
            <Button color="inherit" onClick={goProfile}>
              <Box display="flex" flexDirection="column">
                <Typography
                  variant="subtitle1"
                  component="div"
                  color={"white"}
                  fontWeight={600}
                  textTransform={"none"}
                  textAlign={"right"}
                >
                  Welcome
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="div"
                  color={"white"}
                  textTransform={"none"}
                  textAlign={"right"}
                >
                  {profile?.name}
                </Typography>
              </Box>
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </div>
  );
}
