import { createTheme, Theme } from "@mui/material/styles";

// Generate a dynamic theme based on the user's color and dark mode preferences.
const generateDynamicTheme = (): Theme => {
  let mainColor = "#F97D45";
  let secondaryColor = "#F9A345";

  return createTheme({
    typography: {
      fontFamily: "Kollektif, Arial, sans-serif",
      allVariants: {
        color: "#222222",
      },
    },
    palette: {
      mode: "light",
      primary: {
        main: mainColor,
      },
      secondary: {
        main: secondaryColor,
      },
      background: {
        default: mainColor,
        paper: "#f0f2f5",
      },
    },
    components: {
      MuiLink: {
        styleOverrides: {
          root: {
            cursor: "pointer",
          },
        },
      },
      MuiPaper: {
        defaultProps: {
          elevation: 7,
        },
        styleOverrides: {
          root: {
            padding: 40,
            borderRadius: 8,
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          "@global": {
            "*": {
              transition:
                "color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out",
            },
          },
        },
      },
    },
    transitions: {
      duration: {
        shortest: 150,
        shorter: 200,
        short: 250,
        standard: 300,
        complex: 375,
        enteringScreen: 225,
        leavingScreen: 195,
      },
    },
  });
};

export default generateDynamicTheme;
