import { FC } from "react";

import { Box, Container } from "@mui/material";
import { Route, Routes, useLocation } from "react-router-dom";

import ROUTES from "./routes";

const Main: FC = () => {
  return (
    <Container maxWidth="sm">
      <Box height="100vh">
        <Pages />
      </Box>
    </Container>
  );
};

const Pages: FC = () => {
  const location = useLocation();

  return (
    <Routes location={location}>
      {ROUTES.map((route) => (
        <Route key={route.path} path={route.path} element={route.content} />
      ))}
    </Routes>
  );
};

export default Main;
