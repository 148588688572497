import { AccessLevel } from "../api/koyo";

const ACCESS_LEVEL_HIERARCHY: { [key in AccessLevel]: number } = {
  suspended: 0,
  standard: 1,
  recruiter: 2,
  doctor: 3,
  supervisor: 4,
  admin: 5,
};

const hasPermission = (
  accessLevel: AccessLevel,
  requiredAccessLevel: AccessLevel
): boolean => {
  return (
    ACCESS_LEVEL_HIERARCHY[accessLevel] >=
    ACCESS_LEVEL_HIERARCHY[requiredAccessLevel]
  );
};

export default hasPermission;
