import { FC } from "react";

import { Box, Button, Container, Paper, Typography } from "@mui/material";

import SignInForm from "../components/forms/SignIn";

const LoginPage: FC = () => {
  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Container maxWidth="xs">
        <Box marginBottom={2}>
          <Box display="flex" justifyContent="center">
            <img
              src={`${process.env.PUBLIC_URL}/images/koyo_logo_transparent.png`}
              alt="Koyo"
              width={200}
              height={200}
            />
          </Box>
          <Typography
            variant="h6"
            fontWeight={700}
            align="center"
            gutterBottom
            color="white"
          >
            Welcome Back
          </Typography>
          <Typography
            variant="subtitle1"
            fontWeight={500}
            align="center"
            gutterBottom
            color="white"
          >
            Sign in to continue
          </Typography>
        </Box>

        <Paper>
          <SignInForm />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            <Typography variant="body1">Need an account?</Typography>
            <Button href="/create-account">Sign up</Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default LoginPage;
