import { FC, useState } from "react";

import { Box, Button, Dialog, Paper } from "@mui/material";

import NavBar from "../components/navigation/NavBar";

import { Patient } from "../api/koyo";
import CreatePatientForm from "../components/forms/CreatePatient";
import FetchAccountPatients from "../components/fetch/FetchAccountPatients";
import PatientCard from "../components/cards/PatientCard";

const PatientsPage: FC = () => {
  const [patients, setPatients] = useState<Patient[]>([]);
  const [newPatientModal, setNewPatientModal] = useState(false);

  return (
    <Box height="100vh" overflow={"clip"} display="flex" flexDirection="column">
      <Box mt={2} />
      <NavBar />
      <Box height={0.025}></Box>
      <Box
        flexGrow={1}
        overflow="auto"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Paper>
          <Button fullWidth onClick={() => window.history.back()}>
            Back
          </Button>
          <FetchAccountPatients setPatients={setPatients} />
          {patients.map((patient) => {
            return (
              <Box key={patient._id} mt={1}>
                <PatientCard patient={patient} />
              </Box>
            );
          })}
          <Box display="flex" justifyContent="center" mt={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setNewPatientModal(true)}
            >
              Add Family Member
            </Button>
          </Box>
        </Paper>
      </Box>
      <Dialog open={newPatientModal} maxWidth={"xs"}>
        <Button onClick={() => setNewPatientModal(false)}>Cancel</Button>
        <Box mb={2} />
        <CreatePatientForm setNewPatientModal={setNewPatientModal} />
      </Dialog>
    </Box>
  );
};

export default PatientsPage;
