import { useContext } from "react";

import ThemeContext from "../contexts/ThemeContext";

// This hook is a custom hook that allows us to access the ThemeContext across the app.
const useTheme = () => {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error("useTheme must be used within the ThemeProvider.");
  }

  return context;
};

export default useTheme;
