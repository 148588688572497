import { ReactNode, FC, useMemo, useState } from "react";

import { Theme } from "@mui/material/styles";

import generateDynamicTheme from "../utilities/theme";
import ThemeContext from "../contexts/ThemeContext";

// This provider is a wrapper around the ThemeContext.
// Any component that is a child of this provider can access the ThemeContext using the useTheme hook.
const ThemeProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(generateDynamicTheme());

  const state = useMemo(() => ({ theme, setTheme }), [theme]);

  return (
    <ThemeContext.Provider value={state}>{children}</ThemeContext.Provider>
  );
};

export default ThemeProvider;
