import { FC } from "react";

import { Box, Paper, Typography } from "@mui/material";
import { format } from "date-fns";

import { Conversation, Message } from "../api/koyo";
import useAPI from "../hooks/useAPI";

const ConversationDisplay: FC<{ conversation: Conversation }> = ({
  conversation,
}) => {
  return (
    <Box display="flex" flexDirection="column">
      {conversation.messages?.map((message: Message, index: number) => (
        <Box key={index} display="flex" flexDirection="row" mb={2}>
          <DisplayMessage message={message} />
        </Box>
      ))}
    </Box>
  );
};

const DisplayMessage: FC<{ message: Message }> = ({ message }) => {
  const { profile } = useAPI();

  if (message.sender_id === profile?._id) {
    return (
      <Box display="flex" flexDirection="row-reverse" width="100%">
        <Paper
          sx={{
            mt: 1,
            p: 1,
            borderRadius: "10px 10px 0px 10px",
            backgroundColor: "lightgray",
          }}
        >
          <Typography variant="body1">{message.content}</Typography>
          <Box display="flex" justifyContent="flex-end" mt={0.5}>
            <Typography variant="caption">
              {format(new Date(message.created_at!), "MMMM d hh:mm:ss")}
            </Typography>
          </Box>
        </Paper>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="row" width="100%">
      <Paper
        sx={{
          mt: 1,
          p: 1,
          borderRadius: "10px 10px 10px 0px",
          backgroundColor: "#F9A345",
        }}
      >
        <Typography variant="body1">{message.content}</Typography>
        <Box display="flex" justifyContent="flex-start" mt={0.5}>
          <Typography variant="caption">
            {format(new Date(message.created_at!), "MMMM d hh:mm:ss")}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default ConversationDisplay;
