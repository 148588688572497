import { ReactNode } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import ChatIcon from "@mui/icons-material/Chat";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import GavelIcon from "@mui/icons-material/Gavel";
import HomeIcon from "@mui/icons-material/Home";
import KeyIcon from "@mui/icons-material/Key";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import QuickreplyIcon from "@mui/icons-material/Quickreply";
import ThreePIcon from "@mui/icons-material/ThreeP";

import { AccessLevel } from "./api/koyo";
import ActiveConsultationsPage from "./pages/ActiveConsultations";
import AllConsultationsPage from "./pages/AllConsultationsPage";
import ChatPage from "./pages/ChatPage";
import ConversationPage from "./pages/ConversationPage";
import CreateAccountPage from "./pages/CreateAccountPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import MetricsPage from "./pages/MetricsPage";
import MyPatientsPage from "./pages/MyPatientsPage";
import PatientsPage from "./pages/PatientsPage";
import PatientsWaiting from "./pages/PatientsWaitingPage";
import ProfilePage from "./pages/ProfilePage";
import RecruitPage from "./pages/RecruitPage";
import RestrictedPage from "./components/RestrictedPage";
import TermsOfServicePage from "./pages/TermsOfServicePage";

export interface RouteNode {
  path: string;
  label: string;
  icon: ReactNode;
  content: ReactNode;
  showInNav: boolean;
  requiredAccessLevel: AccessLevel;
  size: number;
}

const ROUTES: RouteNode[] = [
  {
    path: "login",
    label: "Login",
    icon: <KeyIcon style={{ fontSize: "50px", color: "#444142" }} />,
    content: <LoginPage />,
    showInNav: false,
    requiredAccessLevel: "suspended",
    size: 4,
  },
  {
    path: "create-account/:referralCodeFromURL?",
    label: "Create account",
    icon: <PersonAddIcon style={{ fontSize: "50px", color: "#444142" }} />,
    content: <CreateAccountPage />,
    showInNav: false,
    requiredAccessLevel: "standard",
    size: 4,
  },
  {
    path: "",
    label: "Home",
    icon: <HomeIcon style={{ fontSize: "50px", color: "#444142" }} />,
    content: (
      <RestrictedPage>
        <HomePage />
      </RestrictedPage>
    ),
    showInNav: false,
    requiredAccessLevel: "standard",
    size: 4,
  },
  {
    path: "chat",
    label: "Begin consultation now",
    icon: (
      <img
        src={`${process.env.PUBLIC_URL}/icons/consult.svg`}
        alt="Chat"
        width="100"
        height="100"
      />
    ),
    content: (
      <RestrictedPage>
        <ChatPage />
      </RestrictedPage>
    ),
    showInNav: true,
    requiredAccessLevel: "standard",
    size: 12,
  },
  {
    path: "Patients-waiting",
    label: "Patients waiting",
    icon: <AnnouncementIcon style={{ fontSize: "50px", color: "#444142" }} />,
    content: (
      <RestrictedPage>
        <PatientsWaiting />
      </RestrictedPage>
    ),
    showInNav: true,
    requiredAccessLevel: "doctor",
    size: 4,
  },
  {
    path: "my-patients",
    label: "My patients",
    icon: <ThreePIcon style={{ fontSize: "50px", color: "#444142" }} />,
    content: (
      <RestrictedPage>
        <MyPatientsPage />
      </RestrictedPage>
    ),
    showInNav: true,
    requiredAccessLevel: "doctor",
    size: 4,
  },
  {
    path: "active-consultations",
    label: "Active consultations",
    icon: <QuickreplyIcon style={{ fontSize: "50px", color: "#444142" }} />,
    content: (
      <RestrictedPage>
        <ActiveConsultationsPage />
      </RestrictedPage>
    ),
    showInNav: true,
    requiredAccessLevel: "doctor",
    size: 4,
  },
  {
    path: "all-consultations",
    label: "All consultations",
    icon: <MarkChatReadIcon style={{ fontSize: "50px", color: "#444142" }} />,
    content: (
      <RestrictedPage>
        <AllConsultationsPage />
      </RestrictedPage>
    ),
    showInNav: true,
    requiredAccessLevel: "supervisor",
    size: 4,
  },
  {
    path: "patients",
    label: "Family",
    icon: <FamilyRestroomIcon style={{ fontSize: "50px", color: "#444142" }} />,
    content: (
      <RestrictedPage>
        <PatientsPage />
      </RestrictedPage>
    ),
    showInNav: true,
    requiredAccessLevel: "standard",
    size: 4,
  },
  {
    path: "recruit",
    label: "Recruit",
    icon: <PersonAddIcon style={{ fontSize: "50px", color: "#444142" }} />,
    content: (
      <RestrictedPage>
        <RecruitPage />
      </RestrictedPage>
    ),
    showInNav: true,
    requiredAccessLevel: "standard",
    size: 4,
  },
  {
    path: "profile",
    label: "Profile",
    icon: <AccountCircleIcon style={{ fontSize: "50px", color: "#444142" }} />,
    content: (
      <RestrictedPage>
        <ProfilePage />
      </RestrictedPage>
    ),
    showInNav: true,
    requiredAccessLevel: "standard",
    size: 4,
  },
  {
    path: "conversation/:userId/:patientId",
    label: "Conversation",
    icon: <ChatIcon style={{ fontSize: "50px", color: "#444142" }} />,
    content: (
      <RestrictedPage>
        <ConversationPage />
      </RestrictedPage>
    ),
    showInNav: false,
    requiredAccessLevel: "standard",
    size: 4,
  },
  {
    path: "metrics",
    label: "Metrics",
    icon: <AutoGraphIcon style={{ fontSize: "50px", color: "#444142" }} />,
    content: (
      <RestrictedPage>
        <MetricsPage />
      </RestrictedPage>
    ),
    showInNav: true,
    requiredAccessLevel: "doctor",
    size: 4,
  },
  {
    path: "terms-of-service",
    label: "Terms of service",
    icon: <GavelIcon style={{ fontSize: "50px", color: "#444142" }} />,
    content: <TermsOfServicePage />,
    showInNav: true,
    requiredAccessLevel: "standard",
    size: 4,
  },
];

export default ROUTES;
