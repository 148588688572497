import { createContext, Dispatch, SetStateAction } from "react";

import { createTheme, Theme } from "@mui/material/styles";

// Application color theme.
interface ThemeState {
  theme: Theme;
  setTheme: Dispatch<SetStateAction<Theme>>;
}

// Default theme state.
const DEFAULT_THEME_STATE: ThemeState = {
  theme: createTheme({}),
  setTheme: () => {},
};

const ThemeStateContext = createContext<ThemeState>(DEFAULT_THEME_STATE);

export default ThemeStateContext;
